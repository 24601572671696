    <template>
    <div class="detail__controls--plug"></div>
    <div class="detail__controls">
        <template v-if="!detail.lock_edit">
            <div class="plug"></div>
            <div class="editor-buttons" v-if="detail.status !== 'public'">
                <div class="editor-btn has-hint" @click="giveCommand('bold')"
                    :class="{ 'active': editor.isActive('bold') }">
                    <svg-icon :name="'bold'" :width="32" :height="32"></svg-icon>
                    <div class="hint bottom-center-direction">
                        Полужирный
                    </div>
                </div>
                <div class="editor-btn has-hint" @click="giveCommand('bulletList')"
                    :class="{ 'active': editor.isActive('bulletList') }">
                    <svg-icon :name="'bullet-list'" :width="32" :height="32"></svg-icon>
                    <div class="hint bottom-center-direction">
                        Маркированный список
                    </div>
                </div>
                <div class="editor-btn has-hint" @click="giveCommand('orderedList')"
                    :class="{ 'active': editor.isActive('orderedList') }">
                    <svg-icon :name="'ordered-list'" :width="32" :height="32"></svg-icon>
                    <div class="hint bottom-center-direction">
                        Нумерованный список
                    </div>
                </div>
                <div class="editor-btn has-hint" @click="giveCommand('h2')"
                    :class="{ 'active': editor.isActive('heading', { level: 2 }) }">
                    <svg-icon :name="'h2'" :width="32" :height="32"></svg-icon>
                    <div class="hint bottom-center-direction">
                        Заголовок 2
                    </div>
                </div>
                <div class="editor-btn has-hint" @click="giveCommand('h3')"
                    :class="{ 'active': editor.isActive('heading', { level: 3 }) }">
                    <svg-icon :name="'h3'" :width="32" :height="32"></svg-icon>
                    <div class="hint bottom-center-direction">
                        Заголовок 3
                    </div>
                </div>
                <div class="editor-btn has-hint" @click="giveCommand('highlight')"
                    :class="{ 'active': editor.isActive('blockquote') }">
                    <svg-icon :name="'highlight'" :width="32" :height="32"></svg-icon>
                    <div class="hint bottom-center-direction">
                        Акцент
                    </div>
                </div>
                <div class="editor-btn border-right has-hint" @click="giveCommand('blockquote')"
                    :class="{ 'active': editor.isActive('blockquoteBlock') }">
                    <svg-icon :name="'blockquote'" :width="32" :height="32"></svg-icon>
                    <div class="hint bottom-center-direction">
                        Прямая речь автора
                    </div>
                </div>
                <div class="editor-btn has-hint" @click="toggleRBlock(1)"
                    :class="{ 'active': this.rBlockActiveNum === 1 }">
                    <svg-icon :name="'r1'" :width="32" :height="32"></svg-icon>
                    <div class="hint bottom-center-direction">
                        Рекламный блок 1
                    </div>
                </div>
                <div class="editor-btn has-hint" @click="toggleRBlock(2)"
                    :class="{ 'active': this.rBlockActiveNum === 2 }">
                    <svg-icon :name="'r2'" :width="32" :height="32"></svg-icon>
                    <div class="hint bottom-center-direction">
                        Рекламный блок 2
                    </div>
                </div>
                <div class="editor-btn has-hint" @click="toggleRBlock(3)"
                    :class="{ 'active': this.rBlockActiveNum === 3 }">
                    <svg-icon :name="'r3'" :width="32" :height="32"></svg-icon>
                    <div class="hint bottom-center-direction">
                        Рекламный блок 3
                    </div>
                </div>
                <div class="editor-btn border-right has-hint" @click="toggleRBlock(4)"
                    :class="{ 'active': this.rBlockActiveNum === 4 }">
                    <svg-icon :name="'r4'" :width="32" :height="32"></svg-icon>
                    <div class="hint bottom-center-direction">
                        Рекламный блок 4
                    </div>
                </div>
                <div class="editor-btn has-hint border-right"  @click="openAddVideoPopup()"
                    :class="{'active': hasVideoLink}">
                    <svg-icon :name="'video3'" :width="32" :height="32"></svg-icon>
                    <div class="hint bottom-center-direction">
                        Видео-версия статьи
                    </div>
                </div>
                <div class="editor-btn has-hint"  @click="openAddLinkPopup()"
                     :class="{'active': editor.isActive('link')}">
                    <svg-icon :name="'link'" :width="32" :height="32"></svg-icon>
                    <div class="hint bottom-center-direction">
                        Вставить ссылку
                    </div>
                </div>
                <div class="editor-btn has-hint border-right" @click.stop="openInput">
                    <svg-icon :name="'file6'" :width="32" :height="32"></svg-icon>
                    <div class="hint bottom-center-direction">
                        Вставить картинку
                    </div>
                </div>
                <div class="editor-btn has-hint" :class="{ disabled : isAutoSave }">
                    <svg-icon v-if="typographyLoad" :name="'preloader'" :width="24" :height="24" class="preloader"></svg-icon>
                    <svg-icon v-else :name="'typograph'" :width="32" :height="32" @click="typography"></svg-icon>
                    <div class="hint bottom-center-direction">
                        Типограф
                    </div>
                </div>
            </div>
            <div class="rule-btns" v-if="$route.params.id !== 'new'">
                <template v-if="detail.status !== 'public'">
                    <button  @click="publicArticle" type="button" name="button" class="btn btn--mini btn--green" >
                        <svg-icon :name="'check'" :width="16" :height="16" class="mr4"></svg-icon>
                        опубликовать статью
                    </button>
                </template>
                <template v-else>
                    <a :href="publicPath+detail.detail_page_url"
                       target="_blank"
                       class="df text-size-12 text-bold text-uppercase mr32">
                        <svg-icon :name="'link3'" :width="16" :height="16" class="mr4"></svg-icon>
                        перейти к статье
                    </a>
                    <button type="button" name="button" class="btn btn--mini btn--red" @click="$router.push(detailPath+'/cancel-publish')" >
                        <svg-icon :name="'back-arrow'" :width="16" :height="16" class="mr4"></svg-icon>
                        снять с публикации
                    </button>
                </template>
            </div>
            <div v-else class="rule-btns"></div>
            <popup @close="link.value = ''" :show="popups.addLink.show" :name="popups.addLink.name"  :width="640">
                <div class="popup__content">
                    <svg-icon :name="'link2'" :width="80" :height="80" class="text-color-yellow"></svg-icon>
                    <div class="text-size-26 text-bold text-center mt12" v-if="!link.value">Добавить ссылку</div>
                    <div class="text-size-26 text-bold text-center mt12" v-else>Изменить ссылку</div>
                    <div class="mt16" style="width: 400px;">
                        <input-styled
                            :auto-focus="true"
                            name="addLink"
                            :placeholder="''"
                            :placeholderInput="'ССЫЛКА'"
                            @enter="setLinkUrl()"
                            v-model="link.value"
                            @blur="link.error=false;link.permError = false;"
                            @focus="link.error=false;link.permError = false;"
                            :error-text="link.value.length > 0 ? 'Используйте ссылку':''"
                            :is-error="link.error">
                        </input-styled>
                    </div>
                    <div class="df mt32">
                        <button class="btn" @click="setLinkUrl">
                            <svg-icon :name="'check'" :width="16" :height="16" class="mr4"></svg-icon>
                            Подтвердить
                        </button>
                    </div>
                    <div class="popup__footer" v-if="link.value">
                        <a @click="clearLinkUrl()" href="javascript:void(0);" class="text-size-12 df">
                            <svg-icon :name="'trash'" :width="12" :height="12" class="mr2"></svg-icon>
                            Удалить ссылку
                        </a>
                    </div>
                </div>
            </popup>
            <popup  @close="screenShotLink.value = ''" :show="popups.addVideo.show" :name="popups.addVideo.name" :width="640">
                <div class="popup__content">
                    <svg-icon :name="'youtube'" :width="80" :height="80" class="text-color-yellow"></svg-icon>
                    <div class="text-size-26 text-bold text-center mt12">Добавить видео-версию статьи</div>
                    <div class="text-size-14 text-center mt8">
                        Внимание! Прикреплённое видео по смыслу<br>
                        должно полностью совпадать со смыслом статьи!
                    </div>
                    <div class="mt16" style="width: 400px;">
                        <input-styled
                            :auto-focus="true"
                            name="category"
                            :placeholder="''"
                            :placeholderInput="'Вставьте ссылку на YouTube...'"
                            @enter="addVideo()"
                            v-model="screenShotLink.value"
                            @focus="screenShotLink.error=false"
                            :error-text="screenShotLink.value.length > 0 ? 'Используйте ссылку на видео с YouTube':''"
                            :is-error="screenShotLink.error">
                        </input-styled>
                    </div>
                    <div class="df mt32">
                        <button class="btn" @click="addVideo">
                            <svg-icon :name="'check'" :width="16" :height="16" class="mr4"></svg-icon>
                            Подтвердить
                        </button>
                    </div>
                </div>
            </popup>
            <input multiple style="opacity: 0;position: absolute; width: 0;" ref="fileInput"
                   @change="onFileAppendEvent($event)" type="file">
            <input style="opacity: 0;position: absolute; width: 0;" ref="fileInputSchema"
                   @change="onSchemaAppendEvent($event)" type="file">
        </template>
        <!-- Правило уже редактируется -->
        <template v-else>
            <div class="text-size-14 text-bold text-color-red text-center df df-center full">
                В данный момент эту статью редактирует {{ detail.lock_edit_user.name }} {{ detail.lock_edit_user.last_name }},
                {{ detail.lock_edit_user.login_to_phone }}. Дождитесь окончания редактирования. Ну или позвоните и попросите выйти.
            </div>
            <div class="close"  @click="$router.push(detailPath)">
                <svg-icon :name="'close'" :width="32" :height="32"></svg-icon>
            </div>
        </template>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import SvgIcon from '@/components/SvgIcon.vue';
import Popup from '@/components/Popup.vue';
import InputStyled from '@/components/forms/InputStyled.vue';
import activeCategories from '@/helpers/composables/activeCategories';

export default {
    components: {
        SvgIcon,
        Popup,
        InputStyled,
    },
    props: {
        isEdit: {
            default() {
                return false;
            },
        },
        editor: {
            default() {
                return false;
            },
        },
        isEditorReady: {
            default() {
                return false;
            },
        },
        hasVideoLink: {
            default() {
                return false;
            },
        },
        detail: {
            default() {
                return {};
            },
        },
        detailPath: {
            default() {
                return '/';
            },
        },
    },
    setup() {
        const { activeSection } = activeCategories();
        return {
            activeSection,
        };
    },
    emits: ['finish', 'typography', 'publish'],
    data() {
        return {
            screenShotLink: {
                value: '',
                error: false,
                btnType: 'normal',
            },
            link: {
                value: '',
                error: false,
                btnType: 'normal',
            },
            appPath: process.env.VUE_APP_PATH_BACK,
            publicPath: process.env.VUE_APP_PATH_PUBLIC,
            isScreencast: false,
            rBlockActiveNum: false,
        };
    },
    computed: {
        ...mapState({
            switches: (state) => state.switches.items,
            popups: (state) => state.popups.items,
            user: (state) => state.auth.user,
            typographyLoad: (state) => state.articles.typographyLoad,
            isAutoSave: (state) => state.articles.isAutoSave,
            authors: (state) => state.articles.authors,
        }),
        activeCommand() {
            let value = '';
            if (this.editor.isActive('bulletList')) {
                value = 'bulletList';
            } else if (this.editor.isActive('orderedList')) {
                value = 'orderedList';
            } else if (this.editor.isActive('heading', { level: 2 })) {
                value = 'h2';
            } else if (this.editor.isActive('heading', { level: 3 })) {
                value = 'h3';
            } else if (this.editor.isActive('blockquote')) {
                value = 'highlight';
            } else if (this.editor.isActive('blockquoteBlock')) {
                value = 'blockquote';
            } else if (this.editor.isActive('boldBlock')) {
                value = 'boldBlock';
            }
            return value;
        },
        currentAuthor() {
            return this.activeSection.author_id && this.authors.length > 0
                ? this.authors.find((item) => +item.id === +this.activeSection.author_id)
                : {};
        },
    },
    watch: {
        isEditorReady() {
            const rBlockNum = document.getElementById('rBlock') ? document.getElementById('rBlock').getAttribute('data-rBlock') : false;
            this.rBlockActiveNum = rBlockNum * 1;
        },
    },
    mounted() {
        this.openLinkEditHandler = this.openAddLinkPopup.bind(this);
        document.addEventListener('openlinkedit', this.openLinkEditHandler);
        this.rBlockActiveNum = document.getElementById('rBlock') ? document.getElementById('rBlock').getAttribute('data-rBlock') : false;

        if (this.rBlockActiveNum) {
            document.removeEventListener('openlinkedit', this.openLinkEditHandler);
        }

        this.editor.on('update', () => {
            if (!document.getElementById('rBlock')) {
                this.rBlockActiveNum = false;
            }
        });
    },
    beforeUnmount() {
        document.removeEventListener('openlinkedit', this.openAddLinkPopup);
    },
    methods: {
        ...mapActions('rules', [
            'save',
        ]),
        giveCommand(commandName) {
            // Проверка активной команды
            if (this.activeCommand === 'bulletList' && commandName !== 'bulletList') {
                this.editor.commands.toggleBulletList();
            } else if (this.activeCommand === 'orderedList' && commandName !== 'orderedList') {
                this.editor.commands.toggleOrderedList();
            } else if (this.activeCommand === 'h2' && commandName !== 'h2') {
                this.editor.commands.toggleHeading({ level: 2 });
            } else if (this.activeCommand === 'h3' && commandName !== 'h3') {
                this.editor.commands.toggleHeading({ level: 3 });
            } else if (this.activeCommand === 'highlight' && commandName !== 'highlight') {
                this.editor.commands.toggleBlockquote();
            } else if (this.activeCommand === 'blockquote' && commandName !== 'blockquote') {
                this.editor.commands.toggleBlockquoteBlock(this.currentAuthor);
            } else if (this.activeCommand === 'boldBlock' && commandName !== 'boldBlock') {
                this.editor.commands.toggleBoldBlock();
            } else if (this.activeCommand === 'bold' && commandName !== 'bold') {
                this.editor.commands.toggleBold();
            }
            if (commandName === 'rBlock') {
                this.editor.commands.focus();
            }
            if (commandName === 'bulletList') {
                this.editor.commands.toggleBulletList();
            } else if (commandName === 'orderedList') {
                this.editor.commands.toggleOrderedList();
            } else if (commandName === 'h2') {
                this.editor.commands.toggleHeading({ level: 2 });
            } else if (commandName === 'h3') {
                this.editor.commands.toggleHeading({ level: 3 });
            } else if (commandName === 'highlight') {
                this.editor.commands.toggleBlockquote();
            } else if (commandName === 'blockquote') {
                this.editor.commands.toggleBlockquoteBlock(this.currentAuthor);
            } else if (commandName === 'boldBlock') {
                this.editor.commands.toggleBoldBlock();
            } else if (commandName === 'bold') {
                this.editor.commands.toggleBold();
            }
        },
        toggleRBlock(num) {
            const existingBlock = document.querySelector(`[data-rBlock="${num}"]`);
            this.giveCommand('rBlock');
            if (existingBlock) {
                this.editor.commands.focus(existingBlock.getAttribute('data-pos') * 1 + 1);
                this.editor.commands.deleteRBlock(existingBlock);
                this.rBlockActiveNum = false;
                return;
            }

            if (this.rBlockActiveNum && this.rBlockActiveNum === num) {
                const elem = document.getElementById('rBlock');
                const pos = elem.getAttribute('data-pos') * 1 + 1;
                this.editor.commands.focus(pos);
                this.$nextTick(() => {
                    this.editor.commands.deleteRBlock();
                    this.rBlockActiveNum = false;
                });
            } else if (this.rBlockActiveNum) {
                const elem = document.getElementById('rBlock');
                const pos = elem.getAttribute('data-pos') * 1 + 1;
                this.editor.commands.focus(pos);
                this.editor.commands.toggleRBlock({ 'data-rBlock': num });
                this.rBlockActiveNum = num;
                return;
            }
            this.editor.commands.insertRBlock({ 'data-rBlock': num });
            this.rBlockActiveNum = num;
        },
        isLink(str) {
            // Regular expression to match URLs
            const urlPattern = /^(https?|ftp|http):\/\/[^\s/$.?#].[^\s]*$/i;
            return urlPattern.test(str);
        },
        setLinkUrl() {
            if (this.isLink(this.link.value)) {
                this.editor.commands.link({ href: this.link.value, target: '_blank' });
                this.$store.commit('popups/close', { name: this.popups.addLink.name });
                this.link.value = '';
            } else {
                this.setError(this.link);
            }
        },
        setError(field) {
            if (field.error) {
                setTimeout(() => {
                    field.error = false;
                }, 50);
                setTimeout(() => {
                    field.error = true;
                    setTimeout(() => {
                        field.error = false;
                        if (field.permError) {
                            setTimeout(() => {
                                field.error = true;
                            }, 250);
                        }
                    }, 250);
                }, 250);
            } else {
                field.error = true;
                field.permError = true;
            }
        },
        clearLinkUrl() {
            this.editor.commands.unsetLink();
            this.link.value = '';
            this.$store.commit('popups/close', { name: this.popups.addLink.name });
        },
        openAddLinkPopup(attr) {
            this.screenShotLink.value = '';
            if (attr && attr.detail && attr.detail !== 1) {
                this.link.value = attr.detail;
            }
            this.$store.commit('popups/open', { name: this.popups.addLink.name });
        },
        addVideo() {
            const matcherLink = /youtu(?:be\.com\/watch\?v=|\.be\/)/;
            const matcherLinkIds = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?[\w?=]*)?/;
            if (this.screenShotLink.value === ''
                || (this.screenShotLink.value !== '' && this.screenShotLink.value.match(matcherLink) === null)) {
                this.screenShotLink.error = true;
            } else {
                const ids = this.screenShotLink.value.match(matcherLinkIds);
                if (ids && ids[1]) {
                    const id = ids[1];
                    this.$store.commit('rules/addFile', { id, mediaType: 'video' });
                    this.$store.commit('popups/close', { name: this.popups.addVideo.name });
                    this.screenShotLink.value = '';
                    this.screenShotLink.btnType = 'normal';
                }
            }
        },
        getExt(name) {
            const parts = name.split('.');
            return parts.length > 1 ? parts.pop().toLowerCase() : '';
        },
        onFileAppendEvent(e) {
            const files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.onFileAppend(files);
        },
        onSchemaAppendEvent(e) {
            const files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.onSchemaFileAppend(files);
        },
        fileAppendValidate(files, extensions, modal) {
            let error = false;
            for (const i in files) {
                if (files[i].size) {
                    const ext = this.getExt(files[i].name);

                    if (!(files[i].size < 10 * 1024 * 1024 && (extensions.includes(ext)))) {
                        error = true;
                    }
                }
            }
            if (error) {
                this.$store.commit('popups/open', { name: modal });
            }
            return !error;
        },
        fileAppendFormData(file, index) {
            const params = {};
            const formData = new FormData();
            formData.append(`files_${index}`, file);
            const id = Math.random().toString(36).substring(2, 9);
            this.editor.commands.image_preloader({ 'data-id': id });
            params.formData = formData;
            return { params, id };
        },
        onSchemaFileAppend(files) {
            if (!files.length) return;
            const extensions = ['jpg', 'jpeg', 'png', 'svg'];
            if (this.fileAppendValidate(files, extensions, this.popups.errorSchemeUpload.name)) {
                const filesId = [];
                for (const f in files) {
                    if (files[f].size) {
                        const { params, id } = this.fileAppendFormData(files[f], f, true);
                        filesId.push({ id });
                        params.isSchemeImage = true;
                        this.$store.dispatch('articles/uploadFiles', params).then((file) => {
                            for (const i in file) {
                                if (file[i].path) {
                                    const ext = this.getExt(file[i].name);
                                    const preload = filesId[i] ? filesId[i].id : false;
                                    this.editor.commands.schema({
                                        'data-href': `${this.appPath}/${file[i].path}`,
                                        'data-id': file[i].id,
                                        'data-type': ext,
                                        preload,
                                    });
                                }
                            }
                        });
                    }
                }
            }
        },
        onFileAppend(files) {
            if (!files.length) return;
            const extensions = ['jpg', 'jpeg', 'png', 'gif'];
            if (this.fileAppendValidate(files, extensions, this.popups.fileErrorModal.name)) {
                const filesId = [];
                for (const f in files) {
                    if (files[f].size) {
                        const { params, id } = this.fileAppendFormData(files[f], f);
                        filesId.push({ id });

                        this.$store.dispatch('articles/uploadFiles', params).then((file) => {
                            for (const i in file) {
                                if (file[i].path) {
                                    const ext = this.getExt(file[i].name);
                                    const preload = filesId[f] ? filesId[f].id : false;
                                    if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'svg') {
                                        this.editor.commands.screenshot({
                                            'data-href': `${process.env.VUE_APP_PATH_BACK}/${file[i].path}`,
                                            'data-id': file[i].id,
                                            'data-small': `${process.env.VUE_APP_PATH_BACK}/${file[i].small}`,
                                            preload,
                                        });
                                    } else {
                                        this.editor.commands.other_file({
                                            'data-href': `${process.env.VUE_APP_PATH_BACK}/${file[i].path}`,
                                            'data-id': file[i].id,
                                            'data-type': ext,
                                            preload,
                                        });
                                    }
                                }
                            }
                        });
                    }
                }
            }
        },
        openAddVideoPopup() {
            this.screenShotLink.value = '';
            this.$store.commit('popups/open', { name: this.popups.addVideoModal.name });
        },
        openInput() {
            this.$refs.fileInput.click();
        },
        finishEdit() {
            this.$emit('finish');
        },
        typography() {
            if (!this.isAutoSave) {
                this.$emit('typography');
            }
        },
        publicArticle() {
            this.$emit('publish');
        },
    },
};
</script>
