import { Blockquote } from '@tiptap/extension-blockquote';

const RBlock = Blockquote.extend({

    name: 'rBlock',
    content: 'text*',
    atom: false,
    addOptions() {
        return {
            num: ['Что выгоднее — оставить планировку от застройщика, сделать проект своими руками или обратиться к дизайнеру? Без разницы — потеря площади, удобства и эргономики гарантирована в любом из этих вариантов. Помочь может только продуманный до миллиметра проект, где учтены все ваши потребности, а главное — просчитаны на 10-15 лет вперёд.',
                'Все косяки застройщика можно исправить во время ремонта, но зачем это делать, если можно исправить всё за счёт застройщика? Именно поэтому нужно принимать квартиру вместе со специалистом из ЗемсКонтроля, чтобы найти все дефекты и косяки, а главное — правильно предъявить их застройщику, чтобы тот не отвертелся.',
                'Если бы мы строили бани, их бы никто не покупал, потому что наши заказчики вообще не парятся. Мы ведем объект от голых стен и до того момента, когда заказчик надевает уютные тапочки и начинает с удовольствием жить.',
                'Среди московских бракоделов невозможно найти хороших спецов по окнам, дверям, потолкам, кондиционерам, кухням, мебели и т.д. Хорошо, что есть ЗемсБаза, где мы собрали своих лучших подрядчиков за последние 20 лет работы. Теперь вам не нужно искать исполнителей и бояться ошибиться, вам нужно просто нажать одну кнопку и связаться с теми, кого одобрил контроль качества от Zems.pro'],
            HTMLAttributes: {},
            linksText: [
                {
                    text: 'Заказать проект от Zems.pro',
                    link: 'https://zems.pro/',
                },
                {
                    text: 'Заказать приёмку',
                    link: 'https://zemscontrol.ru/',
                },
                {
                    text: 'Перейти на ZemsBaza.ru',
                    link: 'https://zemsbaza.ru/',
                },
                {
                    text: 'Оставить заявку на проект',
                    link: 'https://zems.pro/',
                },
            ],
        };
    },
    addAttributes() {
        return {
            'data-rBlock': {
                default: 1,
            },
        };
    },
    parseHTML() {
        return [
            {
                tag: 'div.r-block',
                getAttrs: (dom) => ({
                    'data-rBlock': dom.getAttribute('data-rBlock') ? dom.getAttribute('data-rBlock') : '',
                }),
            },
        ];
    },
    renderHTML({ node }) {
        const num = this.options.num[node.attrs['data-rBlock'] - 1];
        let linkLabel = '';
        let linkUrl = '';
        if (node.attrs['data-rBlock'] === 1) {
            linkLabel = 'Заказать проект от Zems.pro';
            linkUrl = 'https://zems.pro/';
        } else if (node.attrs['data-rBlock'] === 2) {
            linkLabel = 'Заказать приёмку';
            linkUrl = 'https://zemscontrol.ru/';
        } else if (node.attrs['data-rBlock'] === 3) {
            linkLabel = 'Оставить заявку на проект';
            linkUrl = 'https://zems.pro/';
        } else if (node.attrs['data-rBlock'] === 4) {
            linkLabel = 'Перейти на ZemsBaza.ru';
            linkUrl = 'https://zemsbaza.ru/';
        }
        return ['div',
            {
                class: 'r-block',
                tabindex: 1,
                'data-rBlock': node.attrs['data-rBlock'],
            },
            ['div', {
                class: 'r-block__text',
            }, num],
            ['div', { class: 'r-block__profile' },
                ['div', { class: 'r-block__photo' }],
                ['div', { class: 'r-block__info' },
                    ['div', { class: 'name' }, 'Алексей Земсков'],
                    ['div', { class: 'role' }, 'Основатель компании ZEMS.PRO'],
                ],
                ['a', { class: 'rlink', href: linkUrl, target: '_blank' }, linkLabel],
            ],
        ];
    },
    addNodeView() {
        return ({ editor, node, getPos }) => {
            const elem = document.createElement('div');
            let linkLabel = '';
            let linkUrl = '';
            if (node.attrs['data-rBlock'] === 1) {
                linkLabel = 'Заказать проект от Zems.pro';
                linkUrl = 'https://zems.pro/';
            } else if (node.attrs['data-rBlock'] === 2) {
                linkLabel = 'Заказать приёмку';
                linkUrl = 'https://zemscontrol.ru/';
            } else if (node.attrs['data-rBlock'] === 3) {
                linkLabel = 'Оставить заявку на проект';
                linkUrl = 'https://zems.pro/';
            } else if (node.attrs['data-rBlock'] === 4) {
                linkLabel = 'Перейти на ZemsBaza.ru';
                linkUrl = 'https://zemsbaza.ru/';
            }
            elem.classList.add('r-block');
            elem.setAttribute('id', 'rBlock');
            elem.setAttribute('data-rBlock', `${node.attrs['data-rBlock']}`);
            elem.innerHTML = `<div class="r-block__text">${this.options.num[node.attrs['data-rBlock'] - 1]}</div>
            <div class="r-block__profile">
                <div class="r-block__photo"></div>
                <div class="r-block__info">
                    <div class="name">Алексей Земсков</div>
                    <div class="role">Основатель компании ZEMS.PRO</div>
                </div>
                <a target="_blank" href="${linkUrl}" class="rlink">${linkLabel}</a>
            </div>`;
            elem.setAttribute('data-pos', getPos());
            editor.on('update', () => {
                elem.setAttribute('data-pos', getPos());
            });
            return {
                dom: elem,
            };
        };
    },
    addCommands() {
        return {
            toggleRBlock: (attributes) => ({ commands }) => {
                return commands.toggleNode(this.name, 'rBlock', attributes);
            },
            insertRBlock: (attributes) => ({ commands }) => {
                const blockNode = { type: this.name, attrs: attributes };
                return commands.insertContent([blockNode]);
            },
            deleteRBlock: (existingBlock) => ({ commands }) => {
                commands.deleteNode(this.name, 'paragraph');
                commands.focus(existingBlock.getAttribute('data-pos'));
            },
        };
    },
});

export default RBlock;
