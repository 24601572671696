<template>
    <main-header :isBottomHeader="isBottomHeader" :isCategoryHeader="isCategoryHeader" v-if="showRoute"></main-header>
    <div class="wrapper">
        <div class="rules__header">
            <div class="inner-wrapper df">
                <div class="rules__header-left">
                    <template v-if="!isSearch">
                        <span class="section-name">
                            <span>Категория</span>
                        </span>
                        <div class="has-hint">
                            <switch-btn :name="switches.editable.name"
                                v-model="switches.editable.value">
                                <svg-icon :name="'pencil'" :width="13" :height="13"></svg-icon>
                            </switch-btn>
                            <div class="hint bottom-center-direction">
                                Режим редактирования
                            </div>
                        </div>
                    </template>
                </div>
                <div class="rules__header-right" v-if="!isSearch">
                    <span class="subsection-name" @click="scrollToUp">
                        Нюансы ремонта
                    </span>

                    <template v-if="switches.editable.value">
                        <button @click="offSwitch(switches.editable.name)" class="btn btn--mini text-uppercase">
                            <svg-icon :name="'exit2'" :width="12" :height="12" class="mr6"></svg-icon>
                            выйти из режима редактирования
                        </button>
                    </template>

                    <template v-else>
                        <button @click="newRule()" class="btn --trans btn--mini" v-if="!activeSection.lock">
                            <svg-icon :name="'plus'" :width="12" :height="12" class="mr8"></svg-icon>
                            Добавить статью
                        </button>
                    </template>
                </div>
            </div>
        </div>
        <div class="content --rules">
            <div class="container">
                <aside-sticky
                    :marginTop="88"
                    :marginBottom="0"
                    v-if="!isSearch">
                    <aside class="aside aside--left aside--catalog">
                        <aside-sections></aside-sections>
                    </aside>
                </aside-sticky>
                <div class="container__full container-scroll container__right">
                    <articles-list v-if="activeSection.id"></articles-list>
                    <div v-if="loadItems">
                        <svg-icon class="text-color-yellow preloader mt24 ml24" :name="'preloader'" :width="56" :height="56"></svg-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <popup :show="popups.categoryModal.show" :name="popups.categoryModal.name" :width="640" >
        <category-modal :is-change="!!editSubSection.id"></category-modal>
    </popup>
    <popup :show="popups.deleteImpossibleModal.show" :name="popups.deleteImpossibleModal.name" :width="640"
        :is-btn-close="false">
        <delete-impossible-modal></delete-impossible-modal>
    </popup>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import AsideSticky from '@/components/AsideSticky.vue';
import AsideSections from '@/components/rules/AsideSections.vue';
import SwitchBtn from '@/components/forms/SwitchBtn.vue';
import Popup from '@/components/Popup.vue';
import CategoryModal from '@/components/modals/CategoryModal.vue';
import DeleteImpossibleModal from '@/components/modals/DeleteImpossibleModal.vue';
import { scrollTo } from '@/helpers/animate';
import SvgIcon from '@/components/SvgIcon.vue';
import MainHeader from '@/components/MainHeader.vue';
import activeCategories from '@/helpers/composables/activeCategories';
import ArticlesList from '@/components/ArticlesList.vue';

export default {
    name: 'Rules',
    components: {
        AsideSticky,
        AsideSections,
        ArticlesList,
        SwitchBtn,
        Popup,
        SvgIcon,
        MainHeader,
        // Модалки
        CategoryModal,
        DeleteImpossibleModal,
    },
    props: {
        isBottomHeader: {
            default() {
                return false;
            },
        },
        isCategoryHeader: {
            default() {
                return false;
            },
        },
    },
    setup() {
        const { activeSection } = activeCategories();
        return {
            activeSection,
        };
    },
    mounted() {
        this.setLoadItems(true);
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapState({
            popups: (state) => state.popups.items,
            switches: (state) => state.switches.items,
            isSearch: (state) => state.articles.isSearch,
            sections: (state) => state.articles.sections,
            isUserLoaded: (state) => state.auth.isLoaded,
            isBanned: (state) => state.auth.isBanned,
            isNoSuccess: (state) => state.auth.isNoSuccess,
            isNoSuccessSection: (state) => state.auth.isNoSuccessSection,
            loadSubSection: (state) => state.auth.loadSubSection,
            articles: (state) => state.articles.items,
            loadItems: (state) => state.articles.loadItems,
            user: (state) => state.auth.user,
            editSubSection: (state) => state.articles.editSubSection,
        }),
        ...mapGetters({
            needAuth: 'auth/needAuth',
        }),
        showRoute() {
            return !this.needAuth && !this.isBanned && !this.isNoSuccess && !this.isNoSuccessSection && this.isUserLoaded;
        },
    },
    methods: {
        ...mapMutations('articles', [
            'setItem',
            'setLoadItems',
        ]),
        ...mapMutations('switches', [
            'offSwitch',
        ]),
        scrollToUp() {
            scrollTo('up');
        },
        newRule() {
            this.setItem({});
            this.$router.push(`/sid${this.activeSection.id}/new`);
        },
    },
};
</script>
