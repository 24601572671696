<template>
    <div class="close-header"></div>
    <div class="content">
        <div class="center-block">
            <svg-icon :name="'lock2'" :width="80" :height="80" class="text-color-yellow"></svg-icon>
            <div class="text-bold text-size-26 mt12">
                У вас нет доступа к этому правилу
            </div>
            <div class="text-size-14 mt12">
                Для получения доступа обратитесь к тому, кто дал вам эту ссылку
            </div>
            <button type="button" name="button" class="btn text-size-14 mt32" @click="goToDetail">ЛАДНО :(</button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { scrollTo } from '@/helpers/animate';
import SvgIcon from '@/components/SvgIcon.vue';

export default {
    components: {
        SvgIcon,
    },
    props: {
        detailPath: {
            default() {
                return '/';
            },
        },
    },
    data() {
        return {

        };
    },
    computed: {
        ...mapState({
            popups: (state) => state.popups.items,
            switches: (state) => state.switches.items,
        }),
    },
    methods: {
        scrollToUp() {
            scrollTo('up');
        },
        goToDetail() {
            this.$router.push(this.detailPath);
        },
    },
};
</script>

<style lang="sass">

</style>
