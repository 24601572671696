<template>
    <div>
        <div class="popup__content">
            <svg-icon :name="'youtube'" :width="80" :height="80" class="text-color-yellow"></svg-icon>
            <div class="text-size-26 text-bold text-center mt12">Добавить видео-версию статьи</div>
            <div class="text-size-14 text-center mt8">
                Внимание! Прикреплённое видео по смыслу<br>
                должно полностью совпадать со смыслом статьи!
            </div>
            <div class="mt16" style="width: 400px;">
                <input-styled
                        :auto-focus="true"
                        name="link"
                        :placeholderInput="'Вставьте ссылку на YouTube...'"
                        v-model="link.value"
                        :error-text="link.value.length > 0 ? 'Используйте ссылку на видео с YouTube':''"
                        @focus="link.error=false"
                        :is-error="link.error">
                </input-styled>
            </div>
            <div class="df mt32">
                <button class="btn" @click="send">
                    <svg-icon :name="'check'" :width="16" :height="16" class="mr4"></svg-icon>
                    Подтвердить
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import SvgIcon from '@/components/SvgIcon.vue';
import InputStyled from '@/components/forms/InputStyled.vue';

export default {
    name: 'AddVideoModal',
    components: {
        SvgIcon,
        InputStyled,
    },
    mounted() {},
    data() {
        return {
            link: {
                value: '',
                error: false,
            },
        };
    },
    emits: ['set'],
    methods: {
        close() {
            this.$store.commit('popups/close', { name: this.popups.addVideoModal.name });
        },
        send() {
            if (this.link.value.length === 0) {
                this.link.error = true;
            } else {
                const ids = this.link.value.match(/http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?[\w?=]*)?/);
                if (ids && ids[1]) {
                    const id = ids[1];
                    this.$emit('set', id);
                    this.close();
                } else {
                    this.link.error = true;
                }
            }
        },
    },
    computed: {
        ...mapState({
            popups: (state) => state.popups.items,
        }),
    },
};
</script>

<style scoped>

</style>
