<!-- eslint-disable max-len -->
<template>
    <!-- Кнопки редактирования правила -->
    <detail-controls v-if="editable && currentAuthor"
                     ref="detailControls"
                     :detail="detail"
                     @finish="finishEdit"
                     @typography="typography"
                     @publish="checkPublish"
                     :hasVideoLink="(youtube.value || youtube.load)"
                     :detail-path="detailPath"
                     :isEditorReady="isEditorReady"
                     :editor="editor"></detail-controls>

    <!-- Деталка правила -->
    <div class="content" :class="{'preview-page': isPreview}">
        <div class="aside-sticky-block treeview__wrapper"
            :style="{top: editable || isPreview ? '120px' : '72px',
                height: editable ? 'calc(100vh - 120px - 48px)' : 'calc(100vh - 72px - 48px)'}"
            v-if="!isPreview">
            <div class="aside treeview">
                <div class="text-size-18 text-bold text-uppercase text-font-monts">Содержание</div>
                <div class="treeview__list">
                    <div @click="headingFocus(item)"
                         class="treeview__item text-font-monts"
                         :class="`level${item.level - headingMinLevel + 1
                             + (item.level === 3 && !headingIsLvl2 ? -1 : 0)}`" v-for="(item,index) in heading" :key="index">
                        <span :class="{active: isActiveHeader(item, index)}">{{ item.text }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <aside-sticky
                :marginTop="120"
                :marginBottom="0"
                :class="'--preview'"
                :id="'cats'"
                v-if="isPreview">
                <aside class="aside aside--left aside--catalog" id="cats">
                    <div class="text-size-18 text-bold text-uppercase preview__ttl">Категории</div>
                    <div class="preview__nav">
                        <div class="preview__nav-item" v-for="section in sections" :key="section.id"
                             :class="{active: section.id === activeSection.id}">
                            <svg-icon :name="section.code" :width="40" :height="40" class="mr8"></svg-icon>
                            {{ section.name }}
                        </div>
                    </div>
                </aside>
            </aside-sticky>
            <div class="rte">
                <div class="container-scroll container__right" :class="{'--preview': isPreview}">
                    <div class="article-author" v-if="isPreview && currentAuthor">
                        <div class="photo" style="-webkit-background-size: contain;background-size: contain;"
                             :style="{'background-image': 'url('+appPath+currentAuthor.avatar+')'}"></div>
                        <div class="info">
                            <div class="name">
                                {{ currentAuthor.name }}
                            </div>
                            <div class="cat mt4">
                                {{ currentAuthor.post }}
                            </div>
                        </div>
                    </div>
                    <editable-area v-model="title.value"
                        class="article-ttl mb16"
                        @update:modelValue="autoSave();title.error = false;"
                        :placeholder="'Введите заголовок статьи...'"
                        :is-error="title.error"
                        :is-editable="!isPreview"
                        :auto-focus="true"
                        v-if="detail.status !== 'public'"
                        ></editable-area>
                    <div v-else class="article-ttl mb16">{{ detail.name }}</div>
                    <template v-if="!isPreview">
                        <div class="youtube" v-if="youtube.value || youtube.load">
                            <svg-icon :name="'youtube'" :width="48" :height="48" class="text-color-red mr8"></svg-icon>
                            <div class="info">
                                <div class="title">
                                    Видео-версия статьи
                                    <svg-icon style="cursor: pointer;" @click="youtubeDelete"
                                              :name="'trash2'" :width="16" :height="16" class="ml8"></svg-icon>
                                </div>
                                <div class="subtitle mt4">
                                    <template v-if="!youtube.load">{{ youtube.title }}</template>
                                    <template v-else>
                                        <svg-icon :name="'preloader'" :width="16" :height="16"
                                            class="preloader text-color-yellow"></svg-icon>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="article-info">
                            <div class="l">
                                <div class="time">
                                    {{ detail.date }}
                                </div>
                                <div class="views">
                                    <svg-icon :name="'eye'" :width="16" :height="16" class="mr4"></svg-icon>
                                    {{ detail.views }}
                                </div>
                            </div>
                            <div class="r">
                                <a target="_blank" :href="detail.youtube" class="video-link"  v-if="detail.youtube">
                                    <svg-icon :name="'youtube'" :width="24" :height="24" class="text-color-red mr8"></svg-icon>
                                    Смотреть видео-версию этой статьи
                                </a>
                            </div>
                        </div>
                        <div class="table-of-contents mb32 mt16">
                            Содержание
                            <svg-icon :name="'chevron'" :width="24" :height="24" class="icon"></svg-icon>
                        </div>
                    </template>
                    <editor-content :class="{error: inputError}" v-if="!isPreview && currentAuthor.name" class="inp" :editor="editor"/>
                    <div v-if="isPreview" class="inp" v-html="detail.description"></div>
                </div>
            </div>
            <aside-sticky
                :marginTop="120"
                :marginBottom="0"
                :class="'--widgets'"
                :id="'widgets'"
                v-if="!isPreview">
                <aside class="aside aside--left aside--catalog" id="widgets">
                    <div class="widgets" v-if="!isPreview">
                        <div class="widget">
                            <div class="text-bold text-size-16">
                                Символы: {{ inputTextWithoutTag.split(' ').join('').length }}
                            </div>
                            <div class="text-size-16 line-height-25 mt8">
                                Рекомендуемый объем от 20000 символов
                            </div>
                        </div>
                        <div class="widget">
                            <div class="text-bold text-size-16">
                                Внимание!
                            </div>
                            <div class="text-size-16 line-height-25 mt8">
                                Первое изображение будет обложкой статьи
                            </div>
                        </div>
                        <div class="widget --author">
                            <div class="text-bold text-size-16">
                                Автор статьи:
                            </div>
                            <div class="article-author mt12" v-if="currentAuthor">
                                <div class="photo" style="-webkit-background-size: contain;background-size: contain;"
                                     :style="{'background-image': `url('${appPath+currentAuthor.avatar}')`}"></div>
                                <div class="info">
                                    <div class="name">
                                        {{ currentAuthor.name }}
                                    </div>
                                    <div class="cat mt4">
                                        {{ currentAuthor.post }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>
            </aside-sticky>
            <aside-sticky
                :marginTop="120"
                :marginBottom="0"
                :class="'--adv'"
                :id="'adv'"
                v-else
                >
                <aside class="aside aside--left aside--catalog" id="adv">
                    <div class="adv">
                        <div class="step">
                            <div class="l">
                                <div class="start-icon">
                                    <svg-icon :name="'fire'" :width="24" :height="24" class="text-color-black"></svg-icon>
                                </div>
                            </div>
                            <div class="r">
                                <div class="text-size-22 text-color-black text-bold">
                                    Как сделать<br>идеальный ремонт<br>
                                    за 4 шага?
                                </div>
                                <div class="text-size-16 line-height-25 mt8">
                                    Очень просто - нужно пройти эти&nbsp;шаги в определённой последовательности...
                                </div>
                            </div>
                        </div>
                        <div class="step">
                            <div class="l">
                                <div class="icon">
                                    1
                                </div>
                            </div>
                            <div class="r">
                                <div class="text-size-16 text-color-black text-bold ttl">
                                    Умная приёмка
                                </div>
                                <div class="image image1"></div>
                                <div class="text-size-16 line-height-25 mt8">
                                    Первым делом нужно заставить&nbsp;застройщика исправить&nbsp;все косяки, а если не&nbsp;исправит — помочь вам получить с него много денег...
                                </div>
                            </div>
                        </div>
                        <div class="step">
                            <div class="l">
                                <div class="icon">
                                    2
                                </div>
                            </div>
                            <div class="r">
                                <div class="text-size-16 text-color-black text-bold ttl">
                                    Продуманый проект
                                </div>
                                <div class="image image2"></div>
                                <div class="text-size-16 line-height-25 mt8">
                                    Если без проекта собирать машину,&nbsp;может получиться гоночная, а может стиральная. Поэтому ремонт всегда нужно начинать именно с проекта...
                                </div>
                            </div>
                        </div>
                        <div class="step">
                            <div class="l">
                                <div class="icon">
                                    3
                                </div>
                            </div>
                            <div class="r">
                                <div class="text-size-16 text-color-black text-bold ttl">
                                    Качественный ремонт
                                </div>
                                <div class="image image3"></div>
                                <div class="text-size-16 line-height-25 mt8">
                                    По хорошему проекту любой мастер может сделать ремонт, но&nbsp;за ним придётся следить. А&nbsp;как&nbsp;сделать&nbsp;так, чтобы не&nbsp;следить и не нервничать?
                                </div>
                            </div>
                        </div>
                        <div class="step">
                            <div class="l">
                                <div class="icon">
                                    4
                                </div>
                            </div>
                            <div class="r">
                                <div class="text-size-16 text-color-black text-bold ttl">
                                    Комплектация объекта
                                </div>
                                <div class="image image4"></div>
                                <div class="text-size-16 line-height-25 mt8">
                                    На стройматериалах<br>для ремонта можно<br>сэкономить 400-800 тысяч,<br>если закупить их сразу, а затем положить на склад<br>временного хранения...
                                </div>
                            </div>
                        </div>
                        <div class="step">
                            <div class="l">
                                <div class="start-icon">
                                    <svg-icon :name="'fire'" :width="24" :height="24" class="text-color-black"></svg-icon>
                                </div>
                            </div>
                            <div class="r">
                                <div class="text-size-22 text-color-black text-bold">
                                    Резюмируем
                                </div>
                                <div class="text-size-16 line-height-25 mt8">
                                    Хорошо, что есть группа компаний&nbsp;Zems.pro, в которую входят компании по приёмке, проектированию, дизайну, ремонту&nbsp;и комплектации объекта&nbsp;стройматериалами.
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>
            </aside-sticky>
        </div>
        <div class="detail__footer" v-if="!isPreview">
            <div class="l">
                <tooltip
                    v-if="detail.log !== undefined"
                    :direction="'top-left-direction'"
                    :add-style="{width: 383 + 'px'}"
                    :is-btn-close="false">
                    <template v-slot:tooltipBtn>
                        <a href="javascript:void(0);" class="text-size-12" v-if="detail.log[0]"><span class="date">{{ detail.log[0].date }}</span>
                            {{ detail.log[0].user.name }} {{ detail.log[0].user.last_name }}</a>
                    </template>
                    <template v-slot:tooltipContent>
                        <div class="text-uppercase text-bold text-size-13">
                            История изменений
                        </div>
                        <div class="text-size-12 mt8" v-for="log in detail.log" :key="log.id"><span class="date">{{ log.date }}</span>
                            {{ log.user.name }} {{ log.user.last_name }} &mdash; {{ log.event }}</div>
                    </template>
                </tooltip>
            </div>
        </div>
    </div>
    <popup :show="popups.addVideoModal.show" :name="popups.addVideoModal.name" :width="640">
        <addVideoModal @set="setVideo"></addVideoModal>
    </popup>
    <popup :show="popups.addLinkModal.show" :name="popups.addLinkModal.name" :width="640">
        <addLinkModal :is-change="true"></addLinkModal>
    </popup>
    <popup :show="popups.fileErrorModal.show" :name="popups.fileErrorModal.name" :width="640"
           :is-btn-close="false">
        <file-error-modal>
            Допускаются файлы в формате
            <br>JPG, PNG, GIF
            <br>весом не более 30Мб.
        </file-error-modal>
    </popup>
</template>

<script>
import {
    mapState, mapActions, mapMutations, mapGetters,
} from 'vuex';
import { Editor, EditorContent } from '@tiptap/vue-3';

// import BoldBlock from '@/components/editor/tiptop/BoldBlock';
import Placeholder from '@tiptap/extension-placeholder';
import Heading from '@tiptap/extension-heading';
// import Image from '@tiptap/extension-image';
import Italic from '@tiptap/extension-italic';
import History from '@tiptap/extension-history';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import Bold from '@tiptap/extension-bold';
import HardBreak from '@tiptap/extension-hard-break';
import UniqueID from '@tiptap/extension-unique-id';
import Focus from '@tiptap/extension-focus';
import Blockquote from '@tiptap/extension-blockquote';
import Paragraph from '@/components/editor/tiptop/Paragraph';
import Link from '@/components/editor/tiptop/Link';
import Schema from '@/components/editor/tiptop/schema';
import Youtube from '@/components/editor/tiptop/youtube';
import ScreenShot from '@/components/editor/tiptop/screenshot';
import OtherFile from '@/components/editor/tiptop/OtherFile';
import ImagePreloader from '@/components/editor/tiptop/ImagePreloader';
import PasteText from '@/components/editor/tiptop/PasteText';
import Popup from '@/components/Popup.vue';
import AddVideoModal from '@/components/modals/AddVideoModal.vue';
import AddLinkModal from '@/components/modals/AddLinkModal.vue';
import FileErrorModal from '@/components/modals/FileErrorModal.vue';
import activeCategories from '@/helpers/composables/activeCategories';
import BlockquoteBlock from '@/components/editor/tiptop/BlockqouteBlock';
import RBlock from '@/components/editor/tiptop/RBlock';
import youtube from '@/helpers/util/youtube';
import SvgIcon from '@/components/SvgIcon.vue';
import AsideSticky from '@/components/AsideSticky.vue';
import EditableArea from '@/components/forms/EditableArea.vue';
import Tooltip from '@/components/Tooltip.vue';
import { scrollTo } from '@/helpers/animate';
import DetailControls from '@/components/detail/DetailControls.vue';

export default {
    components: {
        Tooltip,
        DetailControls,
        EditorContent,
        Popup,
        EditableArea,
        SvgIcon,
        AsideSticky,
        // модалки
        AddVideoModal,
        AddLinkModal,
        FileErrorModal,
    },
    props: {
        detail: {
            default() {
                return {};
            },
        },
        detailPath: {
            default() {
                return '/';
            },
        },
        editable: {
            default() {
                return true;
            },
        },
        bigImages: {
            default() {
                return false;
            },
        },
        isPreview: {
            default() {
                return false;
            },
        },
    },

    setup() {
        const { activeSection } = activeCategories();
        return {
            activeSection,
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.currentUserName = `${this.user.name} ${this.user.last_name}`;
            if (this.detail.id > 0) {
                this.setLoadEditArticle(true);
                this.setShowButtons(true);
                this.setAutoSaveLog(true);
                if (this.detail.name !== 'Новая статья') {
                    this.title.value = this.detail.name;
                }
                if (this.detail.youtube) {
                    const ids = this.detail.youtube.match(/http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?[\w?=]*)?/);
                    if (ids && ids[1]) {
                        this.setVideo(ids[1], true);
                    }
                }
                if (this.detail.description) {
                    this.editor.commands.setContent(this.detail.description.replace(/(&nbsp;)/g, ' ').replace(/(?:\r\n|\r|\n)/g, '<br />'));
                    this.editor.commands.focus('start');
                    this.updateImages(this.detail.description);
                }
                if (this.editable) {
                    this.checkLockEdit({ id: this.detail.id }).then((r) => {
                        if (!r.lock_edit) {
                            this.setLockEdit({ id: this.detail.id });
                        }
                        setTimeout(() => {
                            this.setLoadEditArticle(false);
                        }, 50);
                    });
                }
            } else {
                this.setShowButtons(false);
            }
        });
    },
    beforeUnmount() {
        if (this.$route.params.id !== 'new' && this.inputText !== this.detail.description && this.detail.status !== 'public' && this.inputText.trim().length > 0) {
            this.save({
                id: this.detail.id,
                formData: {
                    description: this.inputText,
                    title: this.detail.name,
                    youtube: this.youtube.value,
                    typography: true,
                    operation: 'save',
                    symbols: this.inputTextWithoutTag.split(' ').join('').length,
                },
            });
        }
    },
    data() {
        return {
            // для комментов
            appPath: process.env.VUE_APP_PATH_BACK,
            dateTimeFormat: 'dd.MM.yyyy HH:mm',
            currentUserName: '',
            showViewer: {
                value: false,
                index: 0,
            },
            title: {
                value: '',
                error: false,
            },
            youtube: {
                value: '',
                title: '',
                load: false,
            },
            isTextSelected: {},
            alreadyEditing: false, // Правило уже редактируют
            focused: false,
            isCurAfterPasteFile: false,
            scrollPositionAfterPasteFile: false,
            inputText: '',
            inputError: false,
            inputTextWithoutTag: '',
            isEditorReady: false,
            inputJson: {},
            isChangeRule: false,
            timerSave: false,
            currentSelection: false,
            heading: [],
            headingMinLevel: 3,
            headingIsLvl2: false,
            sliderOptions: {
                navButtons: true,
                dots: false,
            },
            acceptMark: {
                value: false,
                error: false,
            },
            fieldTop: 0,
            editor: new Editor({
                editorProps: {
                    handlePaste: (view, event) => {
                        setTimeout(() => {
                            this.autoSave();
                        }, 150);
                        event.preventDefault();
                        if (event.clipboardData.files.length > 0) {
                            this.isCurAfterPasteFile = true;
                            this.scrollPositionAfterPasteFile = document.documentElement.scrollTop;
                            this.pasteEditor(event);
                            // event.preventDefault();
                        }
                        if (event.currentTarget == null) {
                            this.isCurAfterPasteFile = false;
                            document.documentElement.scrollTop = this.scrollPositionAfterPasteFile;
                        }
                        return false;
                    },
                },
                extensions: [
                    HardBreak,
                    Text,
                    Paragraph,
                    Document,
                    History,
                    ScreenShot,
                    Youtube,
                    OtherFile,
                    ImagePreloader,
                    PasteText,
                    Link,
                    Bold,
                    BlockquoteBlock,
                    Blockquote,
                    Schema,
                    Placeholder.configure({
                        emptyEditorClass: 'is-editor-empty',
                        emptyNodeClass: 'is-empty',
                        placeholder: 'Введите текст статьи и добавьте изображения...',
                        showOnlyWhenEditable: false,
                        showOnlyCurrent: false,
                    }),
                    OrderedList,
                    BulletList,
                    ListItem,
                    Italic,
                    Heading.configure({ levels: [1, 2, 3] }),
                    RBlock,
                    HorizontalRule,
                    UniqueID.configure({
                        types: ['heading'],
                    }),
                    Focus.configure({
                        className: 'has-focus',
                        mode: 'all',
                    }),
                ],
                useBuiltInExtensions: false,
                disableInputRules: true,
                parserOptions: {
                    preserveWhitespace: false,
                },
                editable: !this.detail.lock_edit && this.editable && this.detail.status !== 'public' && !this.typographyLoad,
                onCreate: () => {
                    this.divInput(this.editor.getHTML(), false);
                    this.divInputText(this.editor.getText());
                    this.inputJson = this.editor.getJSON();
                    this.parseContent();
                    this.isEditorReady = true;
                },
                onUpdate: () => {
                    this.divInput(this.editor.getHTML(), true);
                    this.divInputText(this.editor.getText());
                    this.inputJson = this.editor.getJSON();
                    this.parseContent();
                },
                onFocus: (e) => {
                    this.divFocus();
                    this.currentSelection = e.transaction.selection.$from.pos;
                },
                onBlur: () => {
                    this.divBlur();
                    this.currentSelection = false;
                },
                onSelectionUpdate: (e) => {
                    this.currentSelection = e.transaction.selection.$from.pos;
                    this.isTextSelected.value = !!e.transaction.selection.content().size;
                },
            }),
        };
    },
    computed: {
        ...mapState({
            popups: (state) => state.popups.items,
            switches: (state) => state.switches.items,
            form: (state) => state.articles.form,
            showButtons: (state) => state.articles.showButtons,
            autoSaveLog: (state) => state.articles.autoSaveLog,
            marks: (state) => state.auth.user.marks,
            user: (state) => state.auth.user,
            typographyLoad: (state) => state.articles.typographyLoad,
            sections: (state) => state.articles.sections,
            authors: (state) => state.articles.authors,
        }),
        ...mapGetters('auth', [
        ]),
        isEdit() {
            return this.switches.ruleEdit.value;
        },
        isMovingRule() {
            return this.switches.moveRule.value;
        },
        isActive() {
            return this.editor.isActive('bold');
        },
        currentAuthor() {
            return this.activeSection.author_id && this.authors.length > 0
                ? this.authors.find((item) => +item.id === +this.activeSection.author_id)
                : {};
        },
        files() {
            const result = this.detail.description
                .match(/<span[\s\S]*?class="(image viewer-elem)"[\s\S]*?\/span>/gi);
            const files = [];
            for (const r in result) {
                const div = document.createElement('div');
                div.innerHTML = result[r];
                let type;
                let element;

                if (div.querySelector('span.image')) {
                    element = div.querySelector('span.image');
                    type = 'image';
                }
                if (type === 'image') {
                    const href = element.dataset.href;
                    files.push({
                        id: element.dataset.id, path: href, icon: href, mediaType: 'photo',
                    });
                }
            }
            return files;
        },
    },
    methods: {
        ...mapActions('articles', [
            'checkLockEdit',
            'setLockEdit',
            'clearLockEdit',
            'save',
            'saveNew',
            'mark',
            'setWriter',
            'deleteStatusComment',
            'sendTypography',
            'getArticle',
        ]),
        ...mapMutations('articles', [
            'setShowButtons',
            'setFormField',
            'addFile',
            'setLoadEditArticle',
            'setIsAutoSave',
            'setIsAutoSaveOk',
            'setAutoSaveLog',
            'setItemText',
        ]),
        getTop() {
            if (document.getElementsByClassName('has-focus')) {
                const elem = document.getElementsByClassName('has-focus')[0];
                const height = elem?.clientHeight;
                const top = elem?.offsetTop + (height / 2);
                if (!top) return false;
                this.fieldTop = `${top}px`;
            }
            return false;
        },
        scrollToUp() {
            scrollTo('up');
        },
        divFocus() {
            this.focused = true;
        },
        divBlur() {
            this.focused = false;
        },
        divInput(e, autoSave) {
            this.inputError = false;
            if (this.inputText !== e) {
                this.isChangeRule = true;
            }
            this.inputText = e;
            if (autoSave) {
                this.autoSave();
            }
        },
        divInputText(e) {
            this.inputTextWithoutTag = e;
        },
        isActiveHeader(item, index) {
            return this.currentSelection && this.currentSelection >= item.position
                && (this.currentSelection < this.heading[+index + 1]?.position || !this.heading[+index + 1]);
        },
        pasteEditor(event) {
            const items = (event.clipboardData || event.originalEvent.clipboardData).items;
            const files = [];
            let isMixPaste = false;
            for (const index in items) {
                const item = items[index];
                if ((item.kind === 'string') && (item.type.match('^text/plain'))) {
                    isMixPaste = true;
                } else if ((item.kind === 'string') && (item.type.match('^text/html'))) {
                    item.getAsString(() => {
                    });
                    isMixPaste = true;
                } else if ((item.kind === 'string') && (item.type.match('^text/uri-list'))) {
                    item.getAsString(() => {
                    });
                    isMixPaste = true;
                } else if ((item.kind === 'file')) {
                    const blob = item.getAsFile();
                    files.push(blob);
                }
            }
            if (!isMixPaste && files) {
                this.$refs.detailControls.onFileAppend(files);
                event.preventDefault();
            }
            return true;
        },
        updateImages(text) {
            if (text) {
                const result = text.match(/<span[\s\S]*?class="(image|youtube)"[\s\S]*?\/span>/gi);
                const IDs = [];
                const files = [];
                for (const r in result) {
                    const div = document.createElement('div');
                    div.innerHTML = result[r];
                    let type;
                    let element;
                    if (div.querySelector('span.image')) {
                        element = div.querySelector('span.image');
                        type = 'image';
                    }
                    IDs.push(element.dataset.id);
                    if (type === 'image') {
                        IDs.push(element.dataset.id);
                        let host = false;
                        if (element.dataset.href.indexOf('https://zems.pro') > -1) {
                            host = 'https://zems.pro';
                        } else if (element.dataset.href.indexOf('https://rules.su') > -1) {
                            host = 'https://rules.su';
                        }
                        const href = element.dataset.href.replace('https://zems.pro', '').replace('https://rules.su', '');
                        this.addFile({
                            id: element.dataset.id,
                            path: href,
                            icon: href,
                            mediaType: 'photo',
                            host,
                        });
                    }
                    if (type === 'youtube') {
                        const id = element.dataset.id;
                        const path = `//img.youtube.com/vi/${id}/mqdefault.jpg`;
                        this.addFile({
                            id, path, icon: path, mediaType: 'video',
                        });
                    }
                }
                return files;
            }
            return [];
        },
        changeIsAutoSave() {
            this.setIsAutoSave(false);
            this.setIsAutoSaveOk(true);
            setTimeout(() => {
                this.setIsAutoSaveOk(false);
            }, 3000);
        },
        autoSave() {
            clearTimeout(this.timerSave);
            if (this.inputTextWithoutTag.trim().length > 0 || this.title.value.length > 0) {
                const formData = new FormData();
                formData.append('title', this.title.value);
                formData.append('description', this.inputText);
                formData.append('youtube', this.youtube.value);
                formData.append('symbols', this.inputTextWithoutTag.split(' ').join('').length);
                this.setIsAutoSave(true);
                this.timerSave = setTimeout(() => {
                    formData.append('operation', 'save');
                    if (!this.detail.id) {
                        formData.append('category_id', this.activeSection.id);
                        this.saveNew(formData).then((detail) => {
                            this.changeIsAutoSave();
                            if (detail.id) {
                                this.$router.push(`/sid${detail.category_id}/${detail.id}`);
                                setTimeout(() => {
                                    this.title.value = detail.name;
                                }, 1000);
                            }
                        });
                    } else {
                        if (this.autoSaveLog) {
                            formData.append('addSaveToLog', this.autoSaveLog);
                        }
                        this.setAutoSaveLog(false);
                        this.save({ formData, id: this.detail.id }).then(() => {
                            this.changeIsAutoSave();
                        });
                    }
                }, 1000);
            }
        },
        finishEdit() {
            if (this.$route.params.id !== 'new' && this.inputText !== this.detail.description && this.status !== 'public') {
                this.save({
                    id: this.detail.id,
                    description: this.inputText,
                    name: this.detail.name,
                    typography: true,
                    symbols: this.inputTextWithoutTag.split(' ').join('').length,
                }).then(() => {
                    this.getArticle({ id: this.$route.params.id });
                });
            }
            this.$router.push(this.detailPath);
        },
        parseContent() {
            this.heading = [];
            this.headingMinLevel = 3;
            this.headingIsLvl2 = false;
            this.editor.state.doc.descendants((node, pos) => {
                if (node.type.name === 'heading') {
                    if (this.headingMinLevel > node.attrs.level) {
                        this.headingMinLevel = node.attrs.level;
                    }
                    if (+node.attrs.level === 2) {
                        this.headingIsLvl2 = true;
                    }
                    this.heading.push({
                        text: node.content.content[0].text,
                        level: node.attrs.level,
                        size: node.content.size,
                        id: node.attrs.id,
                        position: pos + 1,
                    });
                }
            });
        },
        headingFocus(item) {
            this.editor.commands.focus(item.position > 0 ? item.position : 1, { scrollIntoView: false });
            scrollTo(`[data-id="${item.id}"]`);
        },
        markRule(id) {
            if (!this.acceptMark.value) {
                if (this.acceptMark.error) {
                    this.acceptMark.error = false;
                    setTimeout(() => {
                        this.acceptMark.error = true;
                    }, 10);
                } else {
                    this.acceptMark.error = true;
                }
            } else {
                this.mark(id);
            }
        },
        typography() {
            if (!this.typographyLoad) {
                this.sendTypography({ id: this.detail.id, description: this.inputText }).then(() => {
                    if (this.detail.description.indexOf('<p') === -1) {
                        this.editor.commands.setContent(`<p>${this.detail.description.replace(/(&nbsp;)/g, ' ').replace(/(?:\r\n|\r|\n)/g, '<br />')}<p>`);
                    } else {
                        this.editor.commands.setContent(this.detail.description.replace(/(&nbsp;)/g, ' ').replace(/(?:\r\n|\r|\n)/g, '<br />'));
                    }
                });
            }
        },
        setVideo(id, notSave) {
            this.youtube.load = true;
            const data = youtube.loadInfo(id);
            data.then((r) => {
                this.youtube.value = r.url;
                this.youtube.title = r.title;
                this.youtube.load = false;
                if (!notSave) {
                    this.autoSave();
                }
            });
        },
        youtubeDelete() {
            this.youtube.value = '';
            this.youtube.title = '';
            this.autoSave();
        },
        checkPublish() {
            if (this.title.value.trim().length > 0 && this.inputTextWithoutTag.trim().length > 0) {
                this.$router.push(`${this.detailPath}/publish`);
            } else {
                if (this.title.value.trim().length === 0) {
                    this.title.error = true;
                }
                if (this.inputTextWithoutTag.trim().length === 0) {
                    this.inputError = true;
                }
            }
        },
    },
};
</script>

<style lang="sass">
.ProseMirror
    min-height: 800px
</style>
