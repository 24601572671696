<template>
    <div class="rules">
        <div class="rule"
            v-for="rule in rules"
            :id="'rule'+rule.id" :key="rule.id">
            <div class="rule__sections">
                <span>{{ rule.category.lock ? topSections[0].name : getParentCategory(rule.category.parent).name }}</span>
                <span class="text-color-grey ml4 mr4">/</span>
                <router-link  @click="setSearchQuery('')" :to="'/sid' + (rule.category.lock ? topSections[0].id : rule.category.parent)
                + '/sub' + rule.category.id">{{ rule.category.name }}</router-link>
            </div>
            <router-link @click="setSearchQuery('')" :to="'/sid' + (rule.category.lock ? topSections[0].id : rule.category.parent)
                + '/sub' + rule.category.id + '/rule'+ rule.number" class="rule__link">
                <span class="rule__name has-status"><span v-html="highlightedText(rule.name)"></span><svg-icon
                      :name="'link3'" :width="16" :height="16"></svg-icon></span>
            </router-link>
        </div>
        <div v-if="loadItems">
            <svg-icon class="text-color-gray preloader mt24 ml24" :name="'preloader'" :width="56" :height="56"></svg-icon>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import SvgIcon from '@/components/SvgIcon.vue';

export default {
    name: 'ArticlesListSearch',
    components: {
        SvgIcon,
    },
    created() {

    },
    props: {

    },
    data() {
        return {
            appPath: process.env.VUE_APP_PATH_BACK,
            highlightedId: false,
        };
    },
    mounted() { },
    computed: {
        ...mapState({
            rules: (state) => state.articles.itemsSearch,
            loadItems: (state) => state.articles.loadItems,
            topSections: (state) => state.articles.topSections,
            searchQuery: (state) => state.articles.searchQuery,
        }),
    },
    methods: {
        getParentCategory(parentID) {
            return this.topSections.find((item) => item.id === parentID);
        },
        ...mapMutations('rules', [
            'setSearchQuery',
        ]),
        highlightedText(text) {
            return text.replace(this.searchQuery, `<span class="highlight">${this.searchQuery}</span>`);
        },
    },
    watch: {
        $route: 'fetchData',
    },
};
</script>
<style scoped>

</style>
