<template>
    <div>
        <div class="popup__content">
            <svg-icon :name="'file'" :width="80" :height="80" class="text-color-yellow"></svg-icon>
            <div class="text-size-26 text-bold text-center mt12" v-if="!isChange">Добавить категорию</div>
            <div class="text-size-26 text-bold text-center mt12" v-else>Изменить категорию</div>
            <div class="mt16" style="width: 400px;">
                <input-select
                        @change="form.parent_id.error=false; form.parent_id.value = $event;"
                        @open="form.parent_id.error=false"
                        :disabled="false"
                        :isError="form.parent_id.error"
                        :model-value="topSections.find((item) => item.id === form.parent_id.value)?.name"
                        :options="topSections"
                        :classInput="'text-uppercase text-bold'"
                        :placeholder="'Кому принадлежит?'"
                        :list-style="{'max-height': 'none'}"></input-select>
            </div>
            <div class="mt16" style="width: 400px;">
                <input-styled
                        :auto-focus="true"
                        name="category"
                        :placeholderInput="'НАЗВАНИЕ КАТЕГОРИИ'"
                        @enter="send"
                        v-model="form.name.value"
                        @focus="form.name.error=false"
                        :max-length-num="21"
                        :class-btn="'text-bold text-uppercase'"
                        :is-error="form.name.error">
                </input-styled>
            </div>
            <div class="df mt32">
                <submit-btn class="btn" @click="send" :type="status">
                    <svg-icon :name="'check'" :width="16" :height="16" class="mr4"></svg-icon>
                    Подтвердить
                </submit-btn>
            </div>
            <div class="popup__footer" v-if="isChange">
                <a href="javascript:void(0);" class="text-size-12 df" @click="deleteCategory()">
                    <svg-icon :name="'trash'" :width="12" :height="12" class="mr2"></svg-icon>
                    Удалить категорию
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SubmitBtn from '@/components/forms/SubmitBtn.vue';
import InputStyled from '@/components/forms/InputStyled.vue';
import InputSelect from '@/components/forms/InputSelect.vue';
import Modal from '@/helpers/mixins/modal';
import SvgIcon from '@/components/SvgIcon.vue';
import activeCategories from '@/helpers/composables/activeCategories';

export default {
    name: 'CategoryModal',
    mixins: [Modal],
    props: {
        isChange: {
            default() {
                return false;
            },
        },
    },

    setup() {
        const { activeSection } = activeCategories();
        return {
            activeSection,
        };
    },
    components: {
        InputStyled,
        SubmitBtn,
        InputSelect,
        SvgIcon,
    },
    mounted() {
        this.popup = this.popups.categoryModal.name;
        this.form.parent_id.value = +this.activeSection.id;
        if (this.editSubSection.id) {
            this.form.name.value = this.editSubSection.name;
            this.dispatch = 'articles/updateSubCategory';
        }
    },
    data() {
        return {
            popup: '',
            dispatch: 'articles/newSubCategory',
            form: {
                name: {
                    value: '',
                    error: false,
                    type: 'text',
                    require: true,
                },
                parent_id: {
                    value: false,
                    error: false,
                    type: 'select',
                    require: true,
                },
            },
        };
    },
    methods: {
        ...mapActions('rules', [
            'deleteSubCategory',
        ]),
        ...mapActions('popups', [
            'open',
        ]),
        callback(r) { return r; },
        getData() {
            const result = {};
            for (const f in this.form) {
                result[f] = this.form[f].value;
            }
            if (this.editSubSection.id) {
                result.id = this.editSubSection.id;
            }
            return result;
        },
        deleteCategory() {
            this.deleteSubCategory(this.editSubSection);
        },
    },
    computed: {
        ...mapState({
            popups: (state) => state.popups.items,
            editSubSection: (state) => state.articles.editSubSection,
            topSections: (state) => state.articles.topSections,
        }),
    },
};
</script>

<style scoped>

</style>
