<template>
    <div class="switch" :class="{'active': value}" @click="toggleSwitch">
        <input type="checkbox" :name="name" v-model="value">
        <label :for="name">
            <div class="icon">
                <slot></slot>
            </div>
        </label>
    </div>
</template>

<script>
export default {
    name: 'SwitchBtn',
    components: {

    },
    props: {
        name: {
            default() {
                return '';
            },
        },
        isDisabled: {
            default() {
                return false;
            },
        },
        modelValue: { default: '' },

    },
    data() {
        return {

        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(newValue) {
                this.$emit('update:modelValue', newValue);
            },
        },
    },
    methods: {
        toggleSwitch() {
            this.$store.commit('switches/toggleSwitch', this.name);
            this.$emit('change');
        },

    },
};
</script>

<style lang="sass" scoped>
.switch
    height: 24px
    display: flex
    align-items: center
    [type="checkbox"]
        & + label
            width: 24px
            height: 24px
            background-color: transparent
            border-radius: 24px
            display: block
            position: relative
            cursor: pointer
            color: $link-color
            transition: all .15s ease
            .icon
                width: 100%
                height: 100%
                display: flex
                align-items: center
                justify-content: center
            &:after
                content: none
            &:before
                content: none
            &:hover
                background-color: $blue10
        &:checked
            & + label
                background-color: $yellow !important
                box-shadow: inset 0px 0px 3px #B49D28 !important
                color: $gray !important
    &.text-color-yellow
        [type="checkbox"]
            & + label
                color: $yellow
                &:hover
                    background-color: $yellow10
                    color: $yellow
            &:checked
                & + label
                    color: $gray
</style>
