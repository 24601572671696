<template>
    <div class="wrapper --lk">
        <main-header :isBottomHeader="isBottomHeader" :isCategoryHeader="isCategoryHeader" v-if="showRoute"></main-header>
        <!-- Перемещение правила -->
        <div class="content">
            <div class="center-block --lk">
                <div class="name-char">
                    {{ user.firstName.charAt(0) }}
                </div>
                <div class="text-bold text-size-26 mt24">
                    {{ user.firstName }}, вы успешно авторизованы
                </div>
                <div class="text-size-14 text-center mt8">
                    Ваш логин {{ user.phone }}
                </div>
                <button class="btn mt32" @click="logout">
                    <svg-icon class="mr4" :name="'exit'" :width="13" :height="12"></svg-icon>
                    <span>ВЫЙТИ</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SvgIcon from '@/components/SvgIcon.vue';
import MainHeader from '@/components/MainHeader.vue';

export default {
    components: {
        SvgIcon,
        MainHeader,
    },
    props: {
        isBottomHeader: {
            default() {
                return false;
            },
        },
        isCategoryHeader: {
            default() {
                return false;
            },
        },
    },
    data() {
        return {
            checkbox: false,
            isSend: false,
        };
    },
    mounted() {
        this.checkbox = this.user.tech_settings;
    },
    computed: {
        ...mapState({
            popups: (state) => state.popups.items,
            switches: (state) => state.switches.items,
            user: (state) => state.auth.user,
            isUserLoaded: (state) => state.auth.isLoaded,
            isBanned: (state) => state.auth.isBanned,
            isNoSuccess: (state) => state.auth.isNoSuccess,
            isNoSuccessSection: (state) => state.auth.isNoSuccessSection,
        }),
        showRoute() {
            return !this.needAuth && !this.isBanned && !this.isNoSuccess && !this.isNoSuccessSection && this.isUserLoaded;
        },
    },
    methods: {
        ...mapActions('staff', [
        ]),
        logout() {
            this.$store.dispatch('auth/logout', {});
        },
    },
};
</script>

<style lang="sass" scoped>
.name-char
    width: 60px
    height: 60px
    border-radius: 60px
    background-color: $yellow
    font-size: 40px
    line-height: 48px
    display: flex
    align-items: center
    justify-content: center
</style>
