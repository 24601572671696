import { Node } from '@tiptap/vue-3';

const ImagePreloader = Node.create({
    name: 'image_preloader',
    inline: true,
    group: 'inline',
    selectable: true,
    draggable: true,

    addAttributes() {
        return {
            'data-id': {
                default: null,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'span.file-preloader',
                getAttrs: (dom) => ({
                    'data-id': dom.getAttribute('data-id'),
                }),
            },
        ];
    },
    renderHTML({ node }) {
        return [
            'span',
            {
                class: 'file-preloader',
                'data-id': node.attrs['data-id'],
            },
        ];
    },
    addCommands() {
        return {
            image_preloader: (attrs) => (obj) => {
                const { selection } = obj.state;
                const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos;
                const node = this.type.create(attrs);
                const transaction = obj.state.tr.insert(position, node);
                obj.dispatch(transaction);
            },
        };
    },
    addNodeView() {
        return ({
            HTMLAttributes,
        }) => {
            const container = document.createElement('span');
            container.className = 'file-preloader';
            container.dataset.id = HTMLAttributes['data-id'];
            container.innerHTML = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
            return {
                dom: container,
            };
        };
    },

});

export default ImagePreloader;
