<template>
    <div class="detail__controls--plug --preview"></div>
    <div class="detail__controls --preview">
        <div class="text-size-14 text-bold df left">
            Так эту статью видят обычные пользователи
        </div>
        <div class="right">
            <div class="close"  @click="$router.push(detailPath)">
                <svg-icon :name="'close'" :width="32" :height="32"></svg-icon>
            </div>
        </div>
    </div>
    <detail-edit :is-preview="true" :detail="detail" :detail-path="detailPath" :editable="false" :big-images="bigImages"></detail-edit>
</template>

<script>
import { mapGetters } from 'vuex';
import DetailEdit from '@/components/rules/DetailEdit.vue';
import SvgIcon from '@/components/SvgIcon.vue';

export default {
    components: {
        DetailEdit,
        SvgIcon,
    },
    props: {
        detail: {
            default() {
                return {};
            },
        },
        detailPath: {
            default() {
                return '/';
            },
        },
    },
    data() {
        return {
            bigImages: false,
        };
    },
    computed: {
        ...mapGetters('auth', [
        ]),
    },

};
</script>
