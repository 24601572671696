<template>
    <div v-if="!isSave && !isOk && showClose" class="close" @click="close()">
        <svg-icon :name="'close'" :width="32" :height="32"></svg-icon>
    </div>
    <div class="df text-color-gray60" v-else-if="isSave || isOk">
        <template v-if="isSave">
            <span class="text-size-12 mr4">Изменения сохраняются</span>
            <div class="preloader">
                <svg-icon :name="'preloader'" :width="32" :height="32"></svg-icon>
            </div>
        </template>
        <template v-else-if="isOk">
            <span class="text-size-12 mr4" @click="close()">Изменения сохранены</span>
            <svg-icon @click="close()" :name="'check2'" :width="32" :height="32"></svg-icon>
        </template>
    </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue';

export default {
    name: 'AutoSavePreloader',
    components: {
        SvgIcon,
    },
    props: {
        isSave: {
            default() {
                return false;
            },
        },
        isOk: {
            default() {
                return false;
            },
        },
        showClose: {
            default() {
                return true;
            },
        },
    },
    emits: ['close'],
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>

<style scoped>

</style>
