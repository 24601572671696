export default {
    loadInfo(videoId) {
        const vidurl = `https://www.youtube.com/watch?v=${videoId}`;
        return fetch(`https://noembed.com/embed?dataType=json&url=${vidurl}`)
            .then((res) => res.json())
            .then((data) => {
                return data;
            });
    },
};
