<template>
    <div>
        <div class="popup__content">
            <svg-icon :name="'link2'" :width="80" :height="80" class="text-color-yellow"></svg-icon>
            <div class="text-size-26 text-bold text-center mt12" v-if="!isChange">Добавить ссылку</div>
            <div class="text-size-26 text-bold text-center mt12" v-else>Изменить ссылку</div>
            <div class="mt16" style="width: 400px;">
                <input-styled
                        :auto-focus="true"
                        name="link"
                        :placeholderInput="'Вставьте адрес ссылки...'"
                        v-model="link.value"
                        @focus="link.error=false"
                        :is-error="link.error">
                </input-styled>
            </div>
            <div class="df mt32">
                <button class="btn" @click="close">
                    <svg-icon :name="'check'" :width="16" :height="16" class="mr4"></svg-icon>
                    Подтвердить
                </button>
            </div>
            <div class="popup__footer" v-if="isChange">
                <a href="javascript:void(0);" class="text-size-12 df">
                    <svg-icon :name="'trash'" :width="12" :height="12" class="mr2"></svg-icon>
                    Удалить ссылку
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/helpers/mixins/modal';
import SvgIcon from '@/components/SvgIcon.vue';
import InputStyled from '@/components/forms/InputStyled.vue';

export default {
    name: 'AddLinkModal',
    mixins: [Modal],
    components: {
        SvgIcon,
        InputStyled,
    },
    props: {
        isChange: {
            default() {
                return false;
            },
        },
    },
    mounted() {
        this.popup = this.popups.categoryModal.name;
    },
    data() {
        return {
            link: {
                value: '',
                error: false,
            },
        };
    },
    methods: {
        close() {
            this.$store.commit('popups/close', { name: this.popups.addVideoModal.name });
        },
    },
    computed: {
        ...mapState({
            popups: (state) => state.popups.items,
        }),
    },
};
</script>

<style scoped>

</style>
