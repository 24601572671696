import { Node } from '@tiptap/vue-3';

const ScreenShot = Node.create({

    name: 'screenshot',
    group: 'block',
    selectable: true,
    draggable: true,

    addAttributes() {
        return {
            'data-id': {
                default: null,
            },
            'data-href': {
                default: null,
            },
            'data-small': {
                default: null,
            },
            preload: {
                default: null,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'div.image-wrapper',
                getAttrs: (dom) => ({
                    'data-small': dom.getAttribute('data-small'),
                    'data-href': dom.getAttribute('data-href'),
                    'data-id': dom.getAttribute('data-id'),
                }),
            },
        ];
    },
    renderHTML({ node }) {
        return [
            'div', {
                class: 'image-wrapper',
                'data-small': node.attrs['data-small'],
                'data-href': node.attrs['data-href'],
                'data-id': node.attrs['data-id'],
            }, [
                'img',
                {
                    class: 'image viewer-elem',
                    'data-small': node.attrs['data-small'],
                    'data-href': node.attrs['data-href'],
                    'data-id': node.attrs['data-id'],
                    src: node.attrs['data-small'],
                    clickprevent: `$emit(show,'${node.attrs['data-id']}')`,
                },
            ],
        ];
    },
    addCommands() {
        return {
            screenshot: (attrs) => (obj) => {
                const preloadId = attrs.preload;
                let preloadPos = false;
                obj.state.tr.doc.descendants((node, pos) => {
                    if (node.type.name === 'image_preloader') {
                        if (preloadId === node.attrs['data-id']) {
                            preloadPos = pos;
                        }
                    }
                });
                const { selection } = obj.state;
                const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos;
                const node = this.type.create(attrs);
                let transaction;
                if (preloadPos) {
                    transaction = obj.state.tr.replaceWith(preloadPos - 1, preloadPos + 1, node);
                } else {
                    transaction = obj.state.tr.insert(position - 1, node);
                }
                obj.dispatch(transaction);
            },
        };
    },
    addNodeView() {
        return ({
            HTMLAttributes,
        }) => {
            const wrapper = document.createElement('div');
            wrapper.className = 'image-wrapper';
            const container = document.createElement('img');
            container.className = 'image viewer-elem';
            container.src = HTMLAttributes['data-small'];
            container.alt = `rule-image-${HTMLAttributes['data-id']}`;
            container.dataset.small = HTMLAttributes['data-small'];
            container.dataset.href = HTMLAttributes['data-href'];
            container.dataset.id = HTMLAttributes['data-id'];
            container.innerHTML = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
            container.addEventListener('click', () => {
                document.dispatchEvent(new CustomEvent('showimage', { detail: HTMLAttributes['data-id'] }));
            });
            wrapper.append(container);
            return {
                dom: wrapper,
            };
        };
    },

});

export default ScreenShot;
