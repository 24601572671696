<template>
    <main-header :isBottomHeader="isBottomHeader" :isCategoryHeader="isCategoryHeader"></main-header>
    <div class="comments__header--plug"></div>
    <div class="comments__header">
        <div class="inner-wrapper">
            <div class="nav">
                <div class="nav__item">
                    <router-link :to="'/comments'" class="link">
                        на модерации
                    </router-link>
                </div>
                <div class="nav__item">
                    <router-link :to="'/comments/approve'" class="link">
                        одобренные
                    </router-link>
                </div>
                <div class="nav__item">
                    <router-link :to="'/comments/cancel'" class="link">
                        отклонённые
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <div class="nothing-block comments__nothing" v-if="items.length === 0 && !loadItems">
        <svg-icon :name="'file'" :width="80" :height="80" class="text-color-gray60"></svg-icon>
        <div class="text-size-24 mt16">
            Здесь пока нет ни одного комментария
        </div>
    </div>
    <div class="comments" :style="{'padding-top': '24px'}" v-else>
        <div class="comments__item" v-for="item in items" :key="item.id">
            <div class="comments__profile">
                <div class="df">
                    <div class="image">
                        {{ item.name.charAt(0) }}
                    </div>
                    {{ item.name }}
                </div>
                <tooltip
                    v-if="+item.status > 0"
                    :direction="'bottom-right-direction'"
                    :add-style="{width: 160 + 'px'}"
                    :is-btn-close="false">
                    <template v-slot:tooltipBtn>
                        <svg-icon :name="'dots'" :width="24" :height="24" class="text-color-gray50"></svg-icon>
                    </template>
                    <template v-slot:tooltipContent>
                        <a @mousedown="sendStatus({ status: 'moderation', id: item.id });"
                           href="javascript:void(0);" class="text-size-12">Вернуть на модерацию</a>
                    </template>
                </tooltip>
            </div>
            <div class="comments__text">
                {{ item.text }}
            </div>
            <div class="comments__article-link">
                <router-link :to="'sid'+item.article_category_id"
                             class="link">{{ getSectionName(item.article_category_id) }}</router-link> / <router-link
                :to="'sid'+item.article_category_id+'/'+item.article_id" class="link">{{ item.article_name }}</router-link>
            </div>
            <div class="comments__btns df mt16" v-if="!+item.status">
                <button type="button" class="btn btn--green btn--mini mr24" @click="sendStatus({ status:'approve', id: item.id });">
                    <svg-icon :name="'check'" :width="16" :height="16" class="mr4"></svg-icon>
                    опубликовать
                </button>
                <button type="button" class="btn btn--red-trans btn--mini" @click="sendStatus({ status:'cancel', id: item.id });">
                    <svg-icon :name="'block'" :width="16" :height="16" class="mr4"></svg-icon>
                    отклонить
                </button>
            </div>
        </div>
    </div>
    <div class="container__full container-scroll container__right" v-if="loadItems">
        <svg-icon class="text-color-yellow preloader mt24 ml24" :name="'preloader'" :width="56" :height="56"></svg-icon>
    </div>
</template>

<script>
import {
    mapActions,
    mapState,
} from 'vuex';
import { scrollTo } from '@/helpers/animate';
import MainHeader from '@/components/MainHeader.vue';
import SvgIcon from '@/components/SvgIcon.vue';
import Tooltip from '@/components/Tooltip.vue';

export default {
    name: 'RuleDetail',
    props: {
    },
    components: {
        MainHeader,
        SvgIcon,
        Tooltip,
    },
    data() {
        return {
            init: false,
        };
    },
    mounted() {
        this.getItems({ status: this.$route.params.status, reload: true }).then(() => {
            this.init = true;
        });
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        this.init = false;
        window.removeEventListener('scroll', this.handleScroll);
    },
    computed: {
        ...mapState({
            popups: (state) => state.popups.items,
            notify: (state) => state.popups.notify,
            switches: (state) => state.switches.items,
            user: (state) => state.auth.user,
            items: (state) => state.comments.items,
            loadItems: (state) => state.comments.loadItems,
            sections: (state) => state.articles.sections,
            page: (state) => state.comments.page,
        }),
    },
    methods: {
        ...mapActions('comments', [
            'getItems',
            'sendStatus',
        ]),
        getSectionName(id) {
            return this.sections.find((item) => item.id === id)?.name;
        },
        scrollToUp() {
            scrollTo('up');
        },
        handleScroll() {
            const scroll = window.scrollY;
            const viewport = window.innerHeight;
            const bodyHeight = document.body.clientHeight;
            if (bodyHeight - 80 <= scroll + viewport && this.init) {
                if (!this.loadItems) {
                    this.getItems({ status: this.$route.params.status, reload: false });
                }
            }
        },
        fetchData() {
            this.getItems({ status: this.$route.params.status, reload: true });
        },
    },
    watch: {
        $route: 'fetchData',
    },
};
</script>

<style lang="sass">
.comments
    &__header
        width: 100%
        margin: 0 auto
        background-color: $black1
        position: fixed
        top: 48px
        z-index: 1
        .inner-wrapper
            width: 592px
            padding: 0
            .nav
                &__item
                    .link
                        padding: 8px 8px
                        height: auto
                        height: 32px
        &--plug
            height: 32px
    &__item
        width: 576px
        margin: 0 auto
        padding: 0 0 40px 0
        margin-bottom: 40px
        border-bottom: 1px solid $separator
    &__profile
        display: flex
        font-size: 16px
        line-height: 25px
        font-weight: bold
        align-items: center
        justify-content: space-between
        .tooltip__content
            padding: 8px 16px !important
        .image
            width: 40px
            height: 40px
            border-radius: 40px
            display: flex
            align-items: center
            justify-content: center
            background-size: cover
            background-position: center
            background-color: $yellow
            font-weight: bold
            margin-right: 8px
    &__text
        font-size: 16px
        line-height: 26px
        margin-top: 12px
        font-family: 'monts'
    &__article-link
        font-size: 12px
        line-height: 16px
        margin-top: 8px
        span
            color: $gray50
</style>
