<template>
    <div class="close-header">
        <div class="close" @click="goToDetail">
            <svg-icon :name="'close'" :width="32" :height="32"></svg-icon>
        </div>
    </div>
    <!-- Перемещение правила -->
    <div class="content">
        <div class="center-block" v-if="+detail.lock_edit === 1">
            <svg-icon :name="'warning'" :width="80" :height="80" class="text-color-yellow"></svg-icon>
            <div class="text-bold text-size-26 mt12">
                Перенос статьи в архив временно недоступен
            </div>
            <div class="text-size-14 text-center mt12">
                В данный момент эту статью редактирует<br>
                <span class="text-bold">{{ detail.lock_edit_user.name }} {{ detail.lock_edit_user.last_name }},
                    {{ detail.lock_edit_user.login_to_phone }}.</span><br>
                Дождитесь окончания редактирования.<br>
                Ну или позвоните и попросите выйти.
            </div>
            <button type="button" name="button" class="btn mt32" @click="goToDetail">ПОНЯТНО :(</button>
        </div>
        <div class="center-block" v-else>
            <svg-icon :name="'trash2'" :width="80" :height="80" class="text-color-red"></svg-icon>
            <div class="text-bold text-size-26 text-color-red mt12">
                Отправить статью в архив?
            </div>
            <div class="text-size-14 mt12">
                Вы перестанете её видеть, другие пользователи не смогут её открыть.<br>
                Восстановить статью можно будет только через администратора.
            </div>
            <button type="button" name="button" class="btn btn--red text-uppercase mt32" @click="send()">
                <svg-icon :name="'check'" :width="16" :height="16" class="mr4"></svg-icon>
                ПОДТВЕРДИТЬ
            </button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { scrollTo } from '@/helpers/animate';
import SvgIcon from '@/components/SvgIcon.vue';
import activeCategories from '@/helpers/composables/activeCategories';

export default {
    components: {
        SvgIcon,
    },
    props: {
        detail: {
            default() {
                return {};
            },
        },
        detailPath: {
            default() {
                return '/';
            },
        },
    },

    setup() {
        const { activeSection } = activeCategories();
        return {
            activeSection,
        };
    },
    data() {
        return {

        };
    },
    mounted() {
        this.checkLockEdit({ id: this.detail.id });
    },
    computed: {
        ...mapState({
            popups: (state) => state.popups.items,
            notify: (state) => state.popups.notify,
            switches: (state) => state.switches.items,
        }),
    },
    methods: {
        ...mapActions('articles', [
            'checkLockEdit',
            'archiveArticle',
        ]),
        send() {
            this.archiveArticle({ id: this.detail.id });
            this.$toast.show(this.notify.articleDeleted);
            this.$router.push(`/sid${this.activeSection.id}`);
        },
        scrollToUp() {
            scrollTo('up');
        },
        goToDetail() {
            this.$router.push(this.detailPath);
        },
    },
};
</script>

<style lang="sass">

</style>
