<template>
    <div class="">
        <div class="preloader" v-if="loadItems === true && articles.length === 0"></div>
        <div class="nothing-block" v-else-if="loadItems === false && articles.length === 0">
            <svg-icon :name="'file'" :width="80" :height="80" class="text-color-gray60"></svg-icon>
            <div class="text-size-24 mt16">
                В этой категории пока нет ни одной статьи
            </div>
            <button @click="newRule" class="btn --trans text-uppercase text-size-14 line-height-16 mt32">
                <svg-icon :name="'plus'" :width="12" :height="12" class="mr6"></svg-icon>
                Добавить статью
            </button>
        </div>
        <draggable :forceFallback="true"
                    element="div"
                    @start="dragStart"
                    @end="dragSave"
                    :move="dragStatus"
                    :animation="150"
                    :touchStartThreshold="3"
                    :delay="10"
                    class="rules"
                    :disabled="canDrag"
                    v-model="articles">
            <div class="rule"
                v-for="item in articles" :key="item.id">
                <span v-if="switches.editable.value"  class="rule__link">
                    <span class="rule__name has-status" :class="{'ready': item.status === 'public'}">{{ item.name }}</span>
                    <svg-icon class="drag text-color-gray50" :name="'drag'" :width="8" :height="12"></svg-icon>
                </span>
                <router-link :to="'/sid' + item.category_id + '/'+ item.id" v-else class="rule__link">
                    <span class="rule__name has-status" :class="{'ready': item.status === 'public'}">
                        <span class="ellipsis">
                            {{ item.name }}
                        </span>
                    </span>
                    <div class="rule__right df">
                        <div class="r-num" v-if="item.ad">
                            R{{ item.ad }}
                        </div>
                        <div class="symbols df">
                            <span class="text-size-12">{{ item.symbols }} симв.</span>
                        </div>
                    </div>
                </router-link>
            </div>

        </draggable>
    </div>
</template>

<script>
import {
    mapState, mapActions, mapMutations,
} from 'vuex';
import { VueDraggableNext as draggable } from 'vue-draggable-next';
import SvgIcon from '@/components/SvgIcon.vue';
import activeCategories from '@/helpers/composables/activeCategories';

export default {
    name: 'ArticlesList',
    components: {
        draggable,
        SvgIcon,
    },
    created() {

    },
    props: {

    },
    setup() {
        const { activeSection } = activeCategories();
        return {
            activeSection,
        };
    },
    data() {
        return {
            appPath: process.env.VUE_APP_PATH_BACK,
            highlightedId: false,
            drag: false,
        };
    },
    mounted() {
        this.fetchData();
    },
    computed: {
        ...mapState({
            popups: (state) => state.popups.items,
            switches: (state) => state.switches.items,
            user: (state) => state.auth.user,
            loadItems: (state) => state.articles.loadItems,
            topSections: (state) => state.articles.topSections,
        }),
        canDrag() {
            return !this.switches.editable.value && !this.switches.adminEditable.value;
        },
        articles: {
            get() {
                return this.$store.state.articles.items;
            },
            set(value) {
                this.$store.commit('articles/setItemsLocal', {
                    items: value,
                });
            },
        },
    },
    methods: {
        ...mapActions('articles', [
            'getArticles',
            'setArticlesSort',
        ]),
        ...mapMutations('articles', [
            'setItem',
        ]),
        fetchData() {
            if (this.activeSection.id) {
                this.getArticles({
                    reload: true,
                    section: this.activeSection.id,
                });
            }
        },
        newRule() {
            this.setItem({});
            this.$router.push(`/sid${this.activeSection.id}/new`);
        },
        dragSave() {
            if (this.drag) {
                this.setArticlesSort();
                this.drag = false;
            }
        },
        dragStart() {
        },
        dragStatus() {
            this.drag = true;
        },
    },
    watch: {
        $route: 'fetchData',
    },
};
</script>
<style scoped>

</style>
