<template>
    <div class="editable-area" :contenteditable="isEditable"
        :class="{'error': isError, 'focused': this.focus, fill: filled, 'editable': isEditable}"
        ref="divInput"
        :placeholder="placeholder"
        @focus="setFocus(true)"
        @paste="paste"
        @blur="setFocus(false)"
        @input="updateValue">

    </div>
</template>

<script>

export default {
    components: {
    },
    name: 'EditableArea',
    props: {
        modelValue: { default: '' },
        placeholder: { default: '' },
        label: { default: '' },
        inputId: { default: '' },
        important: { default: false },
        disabled: { default: false },
        isError: { default: false },
        isEditable: { default: true },
        isErrorIcon: { default: false },
        isEdit: { default: false },
        addStyle: { default: '' },
        autoFocus: { default: false },
        isValidate: { default: false },
        errorText: { default: 'Не менее 10-ти символов' },
        classTextarea: {
            default() {
                return [];
            },
            type: Array,
        },
    },
    data() {
        return {
            focus: false,
            counterSMS: 0,
            counterSimbols: 0,
            counterSmsLength: 70,
            repeatError: false,
            titleUpdated: false,
        };
    },
    watch: {
        isError(val, oldVal) {
            if (this.isError) {
                if (this.$el.closest('.popup-wrapper')) {
                    let $top = 0;
                    const pr = this.$el.closest('.popup-wrapper');
                    $top += parseFloat(pr.offsetTop);
                    pr.scrollTo({ top: $top, left: 0, behavior: 'smooth' });
                }
            }
            if (oldVal) {
                this.repeatError = true;
            }
        },
        modelValue(val) {
            if (!this.titleUpdated) {
                this.$refs.divInput.innerText = val;
                this.titleUpdated = true;
                this.placeCaretAtEnd();
            }
        },
    },
    computed: {
        filled() {
            return this.modelValue !== null ? this.modelValue.toString().length > 0 : 0;
        },
        classTextareaField() {
            return { error: this.isError, 'repeat-error': this.repeatError, ...this.classTextarea };
        },
        inputVal: {
            get() { return this.modelValue; },
            set(value) { this.$emit('update:modelValue', value); },
        },
    },
    mounted() {
        this.$refs.divInput.innerText = this.modelValue;
        if (this.autoFocus === true) {
            this.$refs.divInput.focus();
        }
    },
    emits: ['input', 'enter', 'check', 'focus', 'blur', 'edit', 'update:modelValue'],
    methods: {
        paste(e) {
            e.preventDefault();
            const pastedData = e.clipboardData.getData('text');
            document.execCommand('inserttext', false, pastedData);
        },
        updateValue(e) {
            this.$emit('update:modelValue', e.target.innerText);
        },
        check() {
            this.$emit('check');
        },
        placeCaretAtEnd() {
            this.$refs.divInput.focus();
            if (typeof window.getSelection !== 'undefined'
                    && typeof document.createRange !== 'undefined') {
                const range = document.createRange();
                range.selectNodeContents(this.$refs.divInput);
                range.collapse(false);
                const sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);
            } else if (typeof document.body.createTextRange !== 'undefined') {
                const textRange = document.body.createTextRange();
                textRange.moveToElementText(this.$refs.divInput);
                textRange.collapse(false);
                textRange.select();
            }
        },
        setFocus(val) {
            const value = val || false;
            this.repeatError = false;
            this.focus = value;
            if (value) {
                this.$emit('focus');
            } else {
                this.$emit('blur');
            }
        },
        edit() {
            this.$emit('edit');
        },
    },
};
</script>

<style lang="sass">
.editable-area
    width: 100%
    min-height: 64px
    background-color: #fff
    height: auto
    display: block
    resize: none
    font-size: 14px
    padding: 8px 8px
    line-height: 22px
    border-radius: 4px
    border: 1px solid transparent
    transition: all .15s ease
    &[placeholder]:empty::before
        content: attr(placeholder)
        color: $gray50
        font-weight: normal
        text-overflow: ellipsis
        transition: color ease 0.3s
        opacity: 1
        font-weight: bold
    &[placeholder]:empty:focus::before
        content: ""
    &.isEditable:hover
        border-color: $accent
    &:focus
        border-color: $accent
        &::-webkit-input-placeholder
            color: $gray50
            font-weight: normal
            text-overflow: ellipsis
            transition: color ease 0.3s

        &:-moz-placeholder
            color: $gray50
            font-weight: normal
            text-overflow: ellipsis
            transition: color ease 0.3s

        &::-moz-placeholder
            color: $gray50
            font-weight: normal
            text-overflow: ellipsis
            transition: color ease 0.3s

        &:-ms-input-placeholder
            color: $gray50
            font-weight: normal
            text-overflow: ellipsis
            transition: color ease 0.3s
    &.error
        border-color: $red
        color: $red
        &[placeholder]:empty::before
            color: $red !important
</style>
