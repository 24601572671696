<template>
    <div class="header-plug">
        <div class="header-plug__top"></div>
        <div class="header-plug__bottom" v-if="false"></div>
    </div>
    <div class="header">
        <div class="header__top" v-if="!isContractPage">
            <div class="inner-wrapper --container-width df df-space-between">
                <div class="header__left" v-if="isRuleList || isCommentsPage">
                    <div class="logo mr32">
                        <router-link class="logo-link" :to="'/'">
                            <img src="@/assets/img/logo.svg" alt="">
                        </router-link>
                    </div>
                    <div class="nav__wrap df">
                        <div class="nav">
                            <div class="nav__item">
                                <router-link @click="closeSearch()"
                                    :to="'/'"
                                    class="link"
                                    :class="{'router-link-active': isRuleList}">
                                    <span class="name">Статьи</span>
                                </router-link>
                            </div>
                            <div class="nav__item">
                                <router-link @click="closeSearch()"
                                    :to="'/comments'"
                                    :class="{'router-link-active': isCommentsPage}"
                                    class="link">
                                    <span class="name">
                                        Комментарии
                                        <div class="count" v-if="!isCommentsPage && commentModerateCount > 0">
                                            {{ commentModerateCount }}
                                        </div>
                                    </span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="warn" v-if="switches.editable.value">
                        <svg-icon :name="'warning'" :width="16" :height="16" class="text-color-yellow"></svg-icon>
                        Внимание! Порядок статей и категорий на этой странице влияет на порядок статей и категорий на публичном сайте!
                    </div>
                </div>
                <div class="header__right">
                    <div class="df">
                        <template v-if="isRuleList  || isSearch">
                            <!-- <div class="" v-if="!switches.editable.value && isInGroup('admin')">
                                <search-field></search-field>
                            </div> -->
                        </template>
                        <!-- Тултип пользователя -->
                        <div class="header__link-plug" v-if="user.firstName">
                            <router-link :to="'/lk/'" class="header__user">
                                <div class="user-char">{{ user.firstName.charAt(0) }}</div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
// import SearchField from '@/components/SearchField.vue';
import SvgIcon from '@/components/SvgIcon.vue';
import activeCategories from '@/helpers/composables/activeCategories';

export default {
    name: 'MainHeader',
    components: {
        // SearchField,
        SvgIcon,
    },

    setup() {
        const { activeSection } = activeCategories();
        return {
            activeSection,
        };
    },
    props: {
        isBottomHeader: {
            default() {
                return false;
            },
        },
        isCategoryHeader: {
            default() {
                return false;
            },
        },
        isTeamPage: {
            default() {
                return false;
            },
        },
        isContactsPage: {
            default() {
                return false;
            },
        },
        isContractPage: {
            default() {
                return false;
            },
        },
        isSchemePage: {
            default() {
                return false;
            },
        },
        isSectionsSettings: {
            default() {
                return false;
            },
        },
        isStatisticPage: {
            default() {
                return false;
            },
        },
    },
    data() {
        return {
        };
    },
    mounted() {
        this.getModerateCount();
    },
    computed: {
        ...mapState({
            switches: (state) => state.switches.items,
            user: (state) => state.auth.user,
            commentModerateCount: (state) => state.comments.moderateCount,
        }),
        showStatusMenu() {
            return this.isRuleList;
        },
        isRuleList() {
            return this.$route.name === 'rules-home'
                || this.$route.name === 'rules-top'
                || this.$route.name === 'rules'
                || this.$route.name === 'lk';
        },
        isCommentsPage() {
            return this.$route.name === 'comments'
                || this.$route.name === 'comments-home';
        },
        isSearch() {
            return this.$route.name === 'search';
        },
    },
    watch: {

    },
    methods: {
        ...mapMutations('switches', [
            'offSwitch',
            'onSwitch',
        ]),
        ...mapActions('comments', [
            'getModerateCount',
        ]),
        logout() {
            this.$store.dispatch('auth/logout', {});
        },
        closeSearch() {
            this.$store.commit('articles/setIsSearch', false);
            this.$store.commit('switches/offSwitch', 'editable');
        },
    },
};
</script>

<style lang="sass">
</style>
