<template>
    <div class="close-header">
        <div class="close" @click="goToDetail">
            <svg-icon :name="'close'" :width="32" :height="32"></svg-icon>
        </div>
    </div>
    <!-- Перемещение правила -->
    <div class="content">
        <div class="center-block">
            <svg-icon :name="'check2'" :width="80" :height="80" class="text-color-green"></svg-icon>
            <div class="text-center text-size-26 text-bold mt12">
                Опубликовать статью?
            </div>
            <div class="text-size-14 text-center mt12">
                После подтверждения статья будет опубликована на публичном сайте в той категории,<br>
                в которой она сейчас находится
            </div>
            <div class="mt32">
                <check-item @input="form.understand.error = false"
                            v-model="form.understand.value"
                            :is-error="form.understand.error"
                            :inputId="'understand'" :name="'understand'" class="nowrap">
                    Я понимаю, что статья будет доступна всем посетителям сайта ZEMS.PRO
                </check-item>
            </div>

            <submit-btn class="text-size-14 mt32" @click="send" :type="btnStatus">
                <svg-icon :name="'check'" :width="16" :height="16" class="mr4"></svg-icon>
                ПОДТВЕРДИТЬ
            </submit-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { scrollTo } from '@/helpers/animate';
import SvgIcon from '@/components/SvgIcon.vue';
import CheckItem from '@/components/forms/CheckItem.vue';
import SubmitBtn from '@/components/forms/SubmitBtn.vue';
import Modal from '@/helpers/mixins/modal';

export default {
    components: {
        CheckItem,
        SubmitBtn,
        SvgIcon,
    },
    mixins: [Modal],
    props: {
        detail: {
            default() {
                return {};
            },
        },
        detailPath: {
            default() {
                return '/';
            },
        },
    },
    data() {
        return {
            dispatch: 'articles/sendStatus',
            form: {
                understand: {
                    value: false,
                    type: 'check',
                    require: true,
                    error: false,
                },
            },
        };
    },
    computed: {
        ...mapState({
            notify: (state) => state.popups.notify,
            popups: (state) => state.popups.items,
            switches: (state) => state.switches.items,
        }),
    },
    methods: {
        scrollToUp() {
            scrollTo('up');
        },
        goToDetail() {
            this.$router.push(this.detailPath);
        },
        getData() {
            return {
                id: this.detail.id,
                status: 'public',
            };
        },
        callback() {
            this.goToDetail();
            this.scrollToUp();
            this.$toast.show(this.notify.articlePublic);
        },
    },
};
</script>

<style lang="sass">

</style>
