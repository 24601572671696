<template>
    <main-header :isBottomHeader="false" :isCategoryHeader="isCategoryHeader" v-if="showRoute"></main-header>
    <div class="wrapper">
        <div class="rules__header">
            <div class="inner-wrapper df">
                <div class="text-size-20 text-bold text-uppercase">
                    Результаты поиска «<a @click="openSearch()" href="javascript:void(0);">{{ searchQuery }}</a>»
                </div>
            </div>
        </div>
        <div class="content --rules" v-if="rules.length > 0">
            <div class="container">
                <div class="container__full container-scroll container__right container__no-aside">
                    <articles-list-search></articles-list-search>
                </div>
            </div>
        </div>
        <div class="nothing-block" v-if="loadItems === false && rules.length === 0">
            <svg-icon :name="'search'" :width="67" :height="67" class="text-color-gray60"></svg-icon>
            <div class="text-size-24 mt24">
                По запросу «{{ searchQuery }}» ничего не найдено
            </div>
            <button @click="openSearch()" class="btn --trans text-uppercase text-size-14 line-height-16 mt32">
                ИЗМЕНИТЬ ЗАПРОС
            </button>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import MainHeader from '@/components/MainHeader.vue';
import ArticlesListSearch from '@/components/rules/ArticlesListSearch.vue';
import { scrollTo } from '@/helpers/animate';
import SvgIcon from '@/components/SvgIcon.vue';

export default {
    name: 'RulesSearch',
    components: {
        ArticlesListSearch,
        SvgIcon,
        MainHeader,
    },
    props: {
        isBottomHeader: {
            default() {
                return false;
            },
        },
        isCategoryHeader: {
            default() {
                return false;
            },
        },
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapState({
            searchQuery: (state) => state.articles.searchQuery,
            topSections: (state) => state.articles.topSections,
            isUserLoaded: (state) => state.auth.isLoaded,
            isBanned: (state) => state.auth.isBanned,
            isNoSuccess: (state) => state.auth.isNoSuccess,
            isNoSuccessSection: (state) => state.auth.isNoSuccessSection,
            loadSubSection: (state) => state.auth.loadSubSection,
            rules: (state) => state.articles.itemsSearch,
            loadItems: (state) => state.articles.loadItems,
            user: (state) => state.auth.user,
        }),
        ...mapGetters({
            needAuth: 'auth/needAuth',
        }),
        showRoute() {
            return !this.needAuth && !this.isBanned && !this.isNoSuccess && !this.isNoSuccessSection && this.isUserLoaded;
        },
    },
    mounted() {
        if (!this.searchQuery) {
            this.$router.push('/');
        }
    },
    methods: {
        scrollToUp() {
            scrollTo('up');
        },
        openSearch() {
            document.getElementById('search-field-open').click();
        },
    },
};
</script>
