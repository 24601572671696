import { Node } from '@tiptap/vue-3';

const Text = Node.create({

    name: 'pasteText',
    group: 'inline*',
    addAttributes() {
        return {
            text: {
                default: null,
            },
        };
    },
    addCommands() {
        return {
            pasteText: (attrs) => (obj) => {
                const { selection } = obj.state;
                const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos;
                obj.dispatch(obj.state.tr.insertText(attrs.text, position));
            },
        };
    },

});

export default Text;
