<template>
    <detail-edit :detail="detail" :detail-path="detailPath" :editable="true"></detail-edit>
</template>

<script>
import { mapGetters } from 'vuex';
import DetailEdit from '@/components/rules/DetailEdit.vue';

export default {
    components: {
        DetailEdit,
    },
    props: {
        detail: {
            default() {
                return {};
            },
        },
        detailPath: {
            default() {
                return '/';
            },
        },
    },
    computed: {
        ...mapGetters('auth', [
        ]),
    },

};
</script>
