import { Node } from '@tiptap/vue-3';

const Youtube = Node.create({

    name: 'youtube',
    inline: true,
    group: 'inline',
    selectable: true,
    draggable: true,

    addAttributes() {
        return {
            'data-id': {
                default: null,
            },
        };
    },
    parseHTML() {
        return [
            {
                tag: 'div.youtube',
                getAttrs: (dom) => ({
                    'data-id': dom.getAttribute('data-id'),
                }),
            },
        ];
    },
    renderHTML({ node }) {
        return ['div', {
            class: 'youtube',
            'data-id': node.attrs['data-id'],
        }];
    },
    addCommands() {
        return {
            youtube: (attrs) => (obj) => {
                const { selection } = obj.state;
                const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos;
                const node = this.type.create(attrs);
                const transaction = obj.state.tr.insert(position, node);
                obj.dispatch(transaction);
            },
        };
    },
    addNodeView() {
        return ({
            HTMLAttributes,
        }) => {
            const container = document.createElement('div');
            container.className = 'youtube';
            container.innerHTML = `<div class="icon"></div>
                <div class="info">
                    <div class="title">Видео-версия статьи<div class="remove"></div></div>
                    <div class="subtitle">Как нельзя делать ремонт квартиры в новостройке</div>
                </div>`;
            container.setAttribute('data-id', HTMLAttributes['data-id']);
            container.addEventListener('click', () => {
                document.dispatchEvent(new CustomEvent('showvideo', { detail: HTMLAttributes['data-id'] }));
            });
            return {
                dom: container,
            };
        };
    },

});

export default Youtube;
