<template>
<div class="aside__sticky" id="aside-sticky">
    <div class="aside-nav">
        <div class="aside-nav__wrapper">
            <ul class="aside-nav__list">
                <li class="aside-nav__item" v-for="i in sections" :key="i">
                    <span
                        @click="setMovingSection(i.id !== activeSection.id ? i : {})"
                        class="aside-nav__link"
                        :class="{
                            'active': movingSection.id === i.id || !movingSection.id && movingSection.id !== activeSection.id
                                      && activeSection.id === i.id,
                            'current': movingSection.id && movingSection.id !== activeSection.id && activeSection.id === i.id
                        }">
                        <span class="cat-name">{{ i.name }}</span>
                    </span>
                </li>
            </ul>
        </div>
    </div>
</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import activeCategories from '@/helpers/composables/activeCategories';

export default {
    name: 'MovingAsideSections',
    components: {
    },
    created() {},
    mounted() {},
    data() {
        return {
            drag: false,
            disabled: false,
            hoverClass: false,
            appPath: process.env.VUE_APP_PATH_BACK,
        };
    },

    setup() {
        const { activeSection } = activeCategories();
        return {
            activeSection,
        };
    },
    computed: {
        ...mapState({
            popups: (state) => state.popups.items,
            switches: (state) => state.switches.items,
            sections: (state) => state.articles.sections,
            movingSection: (state) => state.articles.movingSection,
        }),

    },
    methods: {
        ...mapActions('popups', [
            'open',
        ]),
        ...mapMutations('articles', [
            'setMovingSection',
        ]),
        closeSearch() {
            this.$store.commit('articles/setIsSearch', false);
        },
    },
};
</script>

<style lang="sass">

</style>
