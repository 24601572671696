<template>
    <div>
        <div class="popup__content">
            <svg-icon :name="'warning'" :width="80" :height="80" class="text-color-red"></svg-icon>
            <div class="text-size-26 text-bold text-center text-color-red mt12">Удаление категории невозможно</div>
            <div class="text-size-14 text-center mt8">
                Перед удалением категории удалите из неё все вложенные правила
            </div>
            <div class="df mt32">
                <button class="btn" @click="close">
                    ПОНЯТНО :(
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/helpers/mixins/modal';
import SvgIcon from '@/components/SvgIcon.vue';

export default {
    name: 'DeleteImpossibleModal',
    mixins: [Modal],
    components: {
        SvgIcon,
    },
    mounted() {
        this.popup = this.popups.categoryModal.name;
    },
    data() {
        return {

        };
    },
    methods: {
        close() {
            this.$store.commit('popups/close', { name: this.popups.deleteImpossibleModal.name });
        },
    },
    computed: {
        ...mapState({
            popups: (state) => state.popups.items,
        }),
    },
};
</script>

<style scoped>

</style>
