import { setBlockType } from 'prosemirror-commands';
import { Node } from '@tiptap/vue-3';

const Paragraph = Node.create({

    name: 'paragraph',
    content: 'inline*',
    group: 'block',

    parseHTML() {
        return [
            { tag: 'p' },
        ];
    },
    renderHTML() {
        return ['p', 0];
    },
    addCommands() {
        return () => setBlockType(this.type);
    },

});

export default Paragraph;
