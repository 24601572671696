import { Node } from '@tiptap/vue-3';
/* eslint-disable */
const OtherFile = Node.create({

    name: 'other_file',
    inline: true,
    group: 'inline',
    selectable: true,
    draggable: true,
    addAttributes() {
        return {
            'data-id': {},
            'data-href': {},
            'data-type': {},
        };
    },

    parseHTML() {
        return [
            {
                tag: 'span.other-file.viewer-elem',
                getAttrs: (dom) => ({
                    'data-type': dom.getAttribute('data-type') ? dom.getAttribute('data-type') : '',
                    'data-href': dom.getAttribute('data-href') ? dom.getAttribute('data-href') : '',
                    'data-id': dom.getAttribute('data-id') ? dom.getAttribute('data-id') : '',
                }),
            },
        ];
    },
    renderHTML({ node }) {
        return [
            'span',
            {
                class: 'other-file viewer-elem',
                'data-type': node.attrs['data-type'],
                'data-href': node.attrs['data-href'],
                'data-id': node.attrs['data-id'],
                clickprevent: `$emit(open,'${node.attrs['data-href']}')`,
            },
            ['span',
                {
                    class: 'text',
                },
                node.attrs['data-type']],
        ];
    },
    addCommands() {
        return {
            other_file: (attrs) => (obj) => {
                const preloadId = attrs.preload;
                let preloadPos = false;
                obj.state.tr.doc.descendants((node, pos) => {
                    if (node.type.name === 'image_preloader') {
                        if (preloadId === node.attrs['data-id']) {
                            preloadPos = pos;
                        }
                    }
                });
                const { selection } = obj.state;
                const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos;
                const node = this.type.create(attrs);
                let transaction;
                if (preloadPos) {
                    transaction = obj.state.tr.replaceWith(preloadPos, preloadPos + 1, node);
                } else {
                    transaction = obj.state.tr.insert(position, node);
                }
                obj.dispatch(transaction);
            },
        };
    },
    addNodeView() {
        return ({
            HTMLAttributes,
        }) => {
            const container = document.createElement('span');
            container.className = 'other-file viewer-elem';
            container.innerHTML = `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="text">${HTMLAttributes['data-type']}</span>`;
            container.dataset.id = HTMLAttributes['data-id'];
            container.dataset.type = HTMLAttributes['data-type'];
            container.dataset.href = HTMLAttributes['data-href'];
            container.addEventListener('click', () => {
                const link = document.createElement('a');
                link.setAttribute('href', HTMLAttributes['data-href']);
                link.setAttribute('target', '_blank');
                link.click();
            });

            return {
                dom: container,
            };
        };
    },

});

export default OtherFile;
